import React, { useEffect, useState } from 'react'
import { BsEye } from 'react-icons/bs'
import { MdEdit } from 'react-icons/md'
import Input from '../../components/input'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAxiosInstance from '../../hooks/useAxios'

const AllOrganisation = () => {
  const path = window.location.pathname
 
  const [AllOrganisation,setAllOrganisation] = useState([])
  const [searchOrg,setSearchOrg] = useState("");
  const [orgLoading,setOrgLoading] = useState(false)
  const axiosInstance = useAxiosInstance()  
  const getOrgImage = (img,name) =>{
    if(img === null || img == ''){
        return `https://wklogo.blob.core.windows.net/logos-small/${name[0]?.toUpperCase()}.png`
    }else{
        return img
    }
    }
  const getFilteredOrgs = (AllOrganisation,searchOrg) =>{
    if(!searchOrg){
        return AllOrganisation
    }
    return AllOrganisation?.filter(item=>item?.name?.toLowerCase().includes(searchOrg?.toLowerCase()) || item?.label?.toLowerCase().includes(searchOrg?.toLowerCase()))
  }

  useEffect(() => {
    setOrgLoading(true);
    axiosInstance
      .get("admin/organisations/")
      .then((res) => {
        setAllOrganisation(res.data.organisations);
        setOrgLoading(false)
        })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting orgs");
        setOrgLoading(false);
      });
  }, []);

  return (
    <>
     <div className='p-6 w-full'>
     <div className='text-2xl font-semibold'>Organisation{path == '/subscriptions-v2' && ' Products'} Management</div>
      {<div className='mt-4'>

        <div className='flex justify-between items-center w-full'> 
            <p className='text-sm font-semibold mb-4'>All Organisation</p>
            {path != '/subscriptions-v2' && 
            <Link to={'/addOrganisation'}><button 
                className='bg-black text-white text-sm rounded-xl px-2 py-1'>Add</button>
            </Link>}
        </div>

        {/* <input pla/> */}
        <Input
        className='w-full'
        placeholder='Search orgs'
        value = {searchOrg}
        onChange = {e=>setSearchOrg(e.target.value)}
        />

       {orgLoading && <p className='mt-4'>Loading orgs...</p>} 
      {!orgLoading &&
      <div className="overflow-x-auto h-[80vh]  overflow-y-auto mt-4 border rounded-lg">
        <table className="w-full">
          <thead className="border-b text-left">
            <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Name</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Image</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Logo</th>
            <th className="px-2 py-1 text-gray-700 text-sm">ID</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Label</th>
            <th className="px-2 py-1 text-gray-700 text-sm">Account Type</th>
			<th className="px-2 py-1 text-gray-700 text-sm">Report Count</th>
          </thead>
          <tbody className="divide-y">
            {(getFilteredOrgs(AllOrganisation,searchOrg) || []).map((org,index)=>{ return <tr key={index} className="bg-white odd:bg-gray-50">
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    {/* <button className='bg-yellow-200 text-white text-xl rounded-xl p-2'><MdEdit/></button> */}
                    <Link to={path=='/subscriptions-v2'? `/subscriptions-v2/edit-product?org_name=${org.name}` : `/editOrganisation/?org_id=${org.org_id}` }><button className='bg-black text-white text-xl rounded-xl p-2'><MdEdit/></button></Link>
                </td>
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    {org.name}
                </td>
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    <div 
                    style={{backgroundImage:`url('${getOrgImage(org?.org_img,org?.name)}')`}} 
                    className="h-[50px] w-full bg-center bg-no-repeat bg-contain"/>
                </td>
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    <div 
                    style={{backgroundImage:`url('${getOrgImage(org?.org_logo,org?.name)}')`}} 
                    className="h-[50px] w-full bg-center bg-no-repeat bg-contain"/>
                </td>
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    {org.org_id}
                </td>
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    {org.label}
                </td>
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    {org?.account_type?.name}
                </td>
                <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                    {org?.report_count}
                </td>
                </tr>    
            })}
          </tbody>
        </table>
      </div>
      }
      </div>
	}	
    </div>
    </> 
  )
}

export default AllOrganisation