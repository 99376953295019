import React, { useState } from 'react'
import useAxiosInstance from '../../hooks/useAxios';
import { toast } from 'react-toastify';

const DalleImgGen = () => {
  const [prompt,setPrompt] = useState("");
  const [resolution,setResolution] = useState("");
  const [img,setImg] = useState("");
  const [isLoading, setIsLoading] = useState("")
  const axiosInstance = useAxiosInstance();
    
  const make_img = () =>{
    setImg('')
    setIsLoading(true)
    axiosInstance.post('/assets/dalle_api/', {prompt, resolution}).then(res=>{
        if (res.data.message == 'success'){
            setImg(res.data.path)
            toast.success('Image generation completed')
        }else{
            toast.error('Image generation failed')
        }
        setIsLoading(false)
    }).catch(error=>{
        toast.error('Image generation failed!')
        setIsLoading(false)
    })
  }

  return (
    <div className='mr-4  p-3 my-5 border rounded-lg bg-white'>
        <p className='text-2xl text-black font-medium mb-4'>Dalle Image Gen</p>

        <p className='text-sm font-semibold text-black mb-2'>Prompt</p>
        <textarea 
            value={prompt}
            onChange={e=>{setPrompt(e.target.value)}}
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
        />

        <p className='text-sm font-semibold mt-4 text-black mb-2'>Resolution</p>

        <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={resolution}
            onChange={(e) => {
              setResolution(e.target.value);
            }}
            placeholder="Enter resolution"
          >
           {['','1024x1024','1792x1024','1024x1792'].map(elem=>{return <option value={elem}>{elem==''?'choose':elem}</option>})} 
          </select>

          <button
            disabled={!prompt || !resolution || isLoading}
            className='bg-black px-4 py-2 text-white font-medium rounded-lg mt-4 disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white'
            onClick={()=>{make_img()}}  
            >
                Make Img
          </button>

          {img&& <>      
          <p className='text-sm font-medium mt-6 mb-4'>Image generated</p>  
          <div className='flex justify-center'>
          <img
            src={img}
            className='h-[600px]'
          />
          </div>

          <a
          href={img}  
          download
          className='bg-black text-white font-medium px-3 py-2 rounded-lg'
          >Download</a>
          
          </>}

          {isLoading && <p>Loading...</p>}
    </div>
  )
}

export default DalleImgGen