import { DateRange, Description, Email, EmailOutlined, LocationCity, Numbers, TypeSpecimen } from '@mui/icons-material'
import { BsFilePlus, BsGlobe, BsGlobeCentralSouthAsia, BsType } from 'react-icons/bs'
import { FaBitcoin, FaCalendarTimes, FaCashRegister, FaFile, FaFilePdf, FaGlobe, FaIdCard, FaIndustry, FaInternetExplorer, FaLayerGroup, FaMailBulk, FaNewspaper, FaSteam, FaUpload, FaUser, FaUserClock, FaUserTie } from 'react-icons/fa'
import { GoGlobe, GoOrganization } from 'react-icons/go'
import { MdDashboard, MdDriveFileRenameOutline, MdLink, MdMarkEmailUnread, MdNotificationsActive, MdOutlineMarkEmailUnread, MdRestartAlt, MdSubscriptions, MdTransform } from 'react-icons/md'

export const typeEnmus = {
    "Scarpper":{"label":"Scarpper","logo":<BsGlobeCentralSouthAsia />},
    "TrialUser":{"label":"Trial User","logo":<FaUserClock/>},
    "CompanyManagement":{"label":"Company Management","logo":<GoOrganization/>},
    "UserManagement":{"label":"User Management","logo":<FaUserTie/>}
}

export const options = [
    {value:'urlScrapper',label:'Url Scrapper',icon:<FaGlobe/>,type: typeEnmus.Scarpper.label},
    {value:'fileUploader',label:'File Uploader',icon:<FaFile/>,type: typeEnmus.Scarpper.label},
	{value:'dalle-img-gen',label:'Dalle Img Generator',icon:<FaFile/>,type: typeEnmus.Scarpper.label},
	{value:'playground',label:'Playground',icon:<FaInternetExplorer/>,type: typeEnmus.Scarpper.label},
    {value:'V2fileUploader',label:'Publish Report',icon:<FaFile/>,type: typeEnmus.TrialUser.label},
    {value:'ActivationEmailTrial',label:'Activation Email Trial',icon:<MdOutlineMarkEmailUnread/>,type:typeEnmus.UserManagement.label},
    {value:'ActivationEmailPaid',label:'Activation Email Paid',icon:<MdMarkEmailUnread/>,type:typeEnmus.UserManagement.label},
    {value:'taskRestarter',label:'Task Restarter',icon:<MdRestartAlt/>,type:typeEnmus.UserManagement.label},
    {value:'dashboardTiles',label:'Dashboard View',icon:<MdDashboard/>,type:typeEnmus.CompanyManagement.label},
    {value:"subscriptions",label:'Subscriptions',icon:<MdSubscriptions/>,type:typeEnmus.CompanyManagement.label},
	{value:"subscriptions-v2",label:'Subscriptions V2',icon:<MdSubscriptions/>,type:typeEnmus.CompanyManagement.label},
    {value:'layouts',label:"Layouts",icon:<FaLayerGroup/>,type:typeEnmus.CompanyManagement.label},
    {value:'companyRequests',label:'Company Requests',icon:<FaMailBulk/>,type:typeEnmus.CompanyManagement.label},
    {value:'companyMigrations',label:'Company Migrations',icon:<MdTransform/>,type:typeEnmus.CompanyManagement.label},
    {value:"generateActivationLink",label:'Generate Activation Link',icon:<MdLink/>,type:typeEnmus.CompanyManagement.label},
    {value:"ActivationEmailBulkAddition",label:'Activation Email Bulk',icon:<FaMailBulk/>,type:typeEnmus.UserManagement.label},
    {value:"FileUploads",label:'File Uploads',icon:<FaUpload/>,type:typeEnmus.UserManagement.label},
    {value:"AllOrganisation",label:'All Organisation',icon:<GoOrganization/>,type:typeEnmus.CompanyManagement.label},
    {value:"AllUsers",label:'All Users',icon:<FaUser/>,type:typeEnmus.UserManagement.label},
    {value:"addCompany",label:'Add Company',icon:<BsFilePlus/>,type:typeEnmus.CompanyManagement.label},
	{value:"all-configs",label:'Newsletter view',icon:<FaNewspaper/>,type:typeEnmus.UserManagement.label},
	{value:"report-page-firmographics",label:'Extented Report View',icon:<FaFilePdf/>,type:typeEnmus.UserManagement.label},
	{value:"report-migrations",label:'Report Migration',icon:<FaFilePdf/>,type:typeEnmus.UserManagement.label},
	{value:"email-sender",label:'Email Sender',icon:<EmailOutlined/>,type:typeEnmus.UserManagement.label},
]

export const CountryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
]

export const ContientList = ['Asia','Africa','Europe','North America','South America','Australia','Antartica']

export const REPORT_PAGE_TABLE_KEYS = [
	{'label':'S.NO.','key':'serial_id','icon': <FaIdCard/>, 'width':'50px' },
	{'label':'Report Name','key':'report_name','icon': <MdDriveFileRenameOutline/> , 'width':'100px'},
	{'label':'Report type','key':'report_type__label','icon': <BsType/> , 'width':'100px'},
	{'label':'Date','key':'date','icon': <FaCalendarTimes/>,'width':'100px'},
	{'label':'User name','key':'user_name','icon': <FaUser/>,'width':'100px'},
	{'label':'Organisation','key':'organisation','icon': <GoGlobe/>,'width':'100px'},
	{'label':'Email','key':'email','icon': <Email/>,'width':'100px'},
	{'label':'Report ID','key':'report_id','icon': <FaIdCard/>,'width':'100px'},
	{'label':'Status','key':'status','icon': <FaSteam/>,'width':'100px'},
	// {'label':'Report ID','key':'status','icon': <FaIdCard/>,'width':'100px'},
	// {'label':'Company Type','key':'company__company_type','icon': <TypeSpecimen/>,'width':'100px'},
	{'label':'Geo','key':'company__geo','icon': <LocationCity/>,'width':'100px'},
	{'label':'Year','key':'company__year','icon': <DateRange/>,'width':'100px'},
	{'label':'Last Funding Date','key':'company__last_funding_date','icon': <DateRange/>,'width':'180px'},
	{'label':'Operating Status','key':'company__operation_status','icon': <MdNotificationsActive/>,'width':'180px'},
	{'label':'# of Employees','key':'company__no_of_employees','icon': <Numbers/>,'width':'100px'},
	{'label':'Website','key':'company__website','icon': <BsGlobe/>,'width':'100px'},
	{'label':'Product Category','key':'company__product_category','icon': <FaCashRegister/>,'width':'250px'},
	{'cut_off':true,'label':'Industry','key':'company__industry','icon': <FaIndustry/>,'width':'300px'},
	// {'cut_off':true,'label':'Company Description','key':'company__description','icon': <Description/>,'width':'300px'},
	{'cut_off':true,'label':'Core offering','key':'company__core_offering','icon': <FaBitcoin/>,'width':'300px'},
]