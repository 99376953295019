import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useAxiosInstance from "../../hooks/useAxios";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "flowbite-react";
import { MdAdd, MdCancel, MdEdit } from "react-icons/md";
import { ModalComp } from "../../components/Modal";
import Select from "react-select";

const SubscriptionPageV2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const axios = useAxiosInstance();
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggleView, setToggleView] = useState("user_data");
  const orgName = searchParams.get("org_name");
  const [hiddenCards, setHiddenCards] = useState([]);
  const [visibleCards, setVisibleCards] = useState([]);
  const [reload, setReload] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalPayload, setModalPayload] = useState({ label: "", value: [] });
  const [mappings, setmappings] = useState({});
  const [docTemplates, setDocTemplates] = useState([]);

  useEffect(() => {
    let _visibleCards = [];
    let _hiddenCards = [];
    setIsLoading(true);
    axios
      .post("/accounts/sandbox_org_data_table_wise/", {
        org_name: orgName,
      })
      .then((res) => {
        setIsLoading(false);
        (res.data[toggleView] || []).forEach((element) => {
          if (
            (element.value || []).length == 0 ||
            (element.value || []).includes("Hidden")
          ) {
            _hiddenCards.push(element);
            // setHiddenCards((prev) => [...prev, element]);
          } else {
            _visibleCards.push(element);
            // setVisibleCards((prev) => [...prev, element]);
          }
        });
        setmappings(res.data.all_filters);
        setDocTemplates(res.data.doc_templates);
        setVisibleCards(_visibleCards);
        setHiddenCards(_hiddenCards);
      })
      .catch((err) => {
        toast.error("Loading data failed");
        setIsLoading(false);
      });
  }, [orgName, toggleView, reload]);

  const submit_products = (label, value) => {
    setIsLoading(true);
    let newElemValue = JSON.parse(JSON.stringify(value || []));
    newElemValue = newElemValue.filter((i) => i != "");
    axios
      .post("/accounts/update_org_product/", {
        org_name: orgName,
        report_type: label,
        subscription_status: newElemValue,
        modification_type: toggleView == "user_data" ? "user" : "admin",
      })
      .then(() => {
        toast.success(`${orgName} for report_label ${label} is done`);
        setReload((prev) => prev + 1);
      })
      .catch((err) => {
        toast.success(`${orgName} for report_label ${label} is failed.`);
      });
  };

  const Card = ({ item, isCancel = false, isEdit = false, isAdd = false }) => {
    return (
      <div className="px-2 py-2 bg-slate-50 border border-[#E5E6E4] rounded-md max-h-max flex justify-between">
        <h5
          className={"font-medium font-Inter-Regular text-base text-[#343434]"}
        >
          {mappings[item.label]?.label}
          <br />
          <p className="text-xs">{mappings[item.label].description}</p>
        </h5>

        <div className="flex gap-x-1 items-center">
          <div className="text-xs font-semibold mr-1">
            {(item.value || []).join(", ")}
          </div>
          {isEdit && (
            <button>
              <MdEdit
                onClick={() => {
                  setModalPayload(item);
                  setModal(true);
                }}
              />
            </button>
          )}

          {isCancel && (
            <button>
              <MdCancel
                onClick={() => {
                  submit_products(item.label, ["Hidden"]);
                }}
              />
            </button>
          )}

          {isAdd && (
            <button
              onClick={() => {
                submit_products(item.label, ["default"]);
              }}
            >
              <MdAdd />
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <ModalComp
        width={400}
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <div className="p-4 rounded-xl bg-white h-[630px]  overflow-y-auto">
          <p className="text-base mb-1 font-medium">
            {mappings[modalPayload.label]?.label}
          </p>
          <p className="text-xs mb-3 font-medium">
            {mappings[modalPayload.label]?.description}
          </p>

          <p className="text-sm mb-1 font-medium">Extra Tag :</p>
          <div>
            <p className="text-sm font-medium">
              <input
                className="mb-2 mr-1"
                onClick={() =>
                  modalPayload.value.includes("Pro")
                    ? setModalPayload((prev) => ({
                        label: prev.label,
                        value: prev.value.filter((p) => p != "Pro"),
                      }))
                    : setModalPayload((prev) => ({
                        label: prev.label,
                        value: [...prev.value, "Pro"],
                      }))
                }
                checked={modalPayload.value.includes("Pro")}
                type="checkbox"
              />
              is Pro
            </p>
            <p className="text-sm font-medium">
              <input
                className="mb-2 mr-1"
                onClick={() =>
                  modalPayload.value.includes("Beta")
                    ? setModalPayload((prev) => ({
                        label: prev.label,
                        value: prev.value.filter((p) => p != "Beta"),
                      }))
                    : setModalPayload((prev) => ({
                        label: prev.label,
                        value: [...prev.value, "Beta"],
                      }))
                }
                checked={modalPayload.value.includes("Beta")}
                type="checkbox"
              />
              is Beta
            </p>
            <p className="text-sm font-medium">
              <input
                className="mb-2 mr-1"
                onClick={() =>
                  modalPayload.value.includes("Lock")
                    ? setModalPayload((prev) => ({
                        label: prev.label,
                        value: prev.value.filter((p) => p != "Lock"),
                      }))
                    : setModalPayload((prev) => ({
                        label: prev.label,
                        value: [...prev.value, "Lock"],
                      }))
                }
                checked={modalPayload.value.includes("Lock")}
                type="checkbox"
              />
              is Lock
            </p>
          </div>

          <p className="text-sm mt-2 mb-1 font-medium">
            Selected PPTX:-{" "}
            {docTemplates.find((it) => it.id == modalPayload.pptx)?.label ||
              "not-selected"}
          </p>
          {
            <Select
              className="w-full"
              options={docTemplates
                .filter((it) => it.doc_type == "pptx")
                .map((elem) => ({ label: elem.label, value: elem.id }))}
              onChange={(e) => {
                console.log(e.value);
                axios
                  .post("/accounts/update_org_product/", {
                    org_name: orgName,
                    report_type: modalPayload.label,
                    pptx: e.value,
                  })
                  .then((res) =>
                    toast.success(
                      `${modalPayload.label} (${orgName}) for pptx is marked successfully`
                    )
                  )
                  .catch((err) => toast.error("Something went wrong!"));
              }}
            />
          }
          <p className="text-sm mt-2 mb-1 font-medium">
            Selected docx:-{" "}
            {docTemplates.find((it) => it.id == modalPayload.docx)?.label ||
              "not-selected"}{" "}
          </p>
          <Select
            className="w-full"
            options={docTemplates
              .filter((it) => it.doc_type == "docx")
              .map((elem) => ({ label: elem.label, value: elem.id }))}
            onChange={(e) => {
              console.log(e.value);
              axios
                .post("/accounts/update_org_product/", {
                  org_name: orgName,
                  report_type: modalPayload.label,
                  docx: e.value,
                })
                .then((res) =>
                  toast.success(
                    `${modalPayload.label} (${orgName}) for docx is marked successfully`
                  )
                )
                .catch((err) => toast.error("Something went wrong!"));
            }}
          />

          <button
            className="mt-2 px-2 py-1 bg-black text-white rounded"
            onClick={() => {
              submit_products(modalPayload.label, modalPayload.value);
            }}
          >
            save
          </button>
        </div>
      </ModalComp>
      {isLoading ? (
        <div className="h-screen w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="min-h-screen overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center w-full">
              <div className="text-2xl  font-semibold">
                Subscriptions of {orgName}
              </div>
              <div className="flex justify-between space-x-2">
                <select
                  className="border rounded-lg px-3 py-2 focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
                  value={toggleView}
                  onChange={(e) => {
                    setToggleView(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"user_data"}>User view</option>
                  <option value={"admin_data"}>Admin view</option>
                </select>
              </div>
            </div>

            <div className="flex w-full mt-3">
              <div className="w-3/4 border p-2 bg-white rounded-md">
                <p className="text-sm font-medium mb-2">Visible Products</p>
                <div className="grid grid-cols-2 gap-2 max-h-max">
                  {visibleCards.sort((a,b)=>mappings[a.label]?.label.localeCompare(mappings[b.label]?.label)).map((elem) => {
                    return <Card item={elem} isCancel={true} isEdit={true} />;
                  })}
                </div>
              </div>

              <div className="w-1/4 ml-2 p-2 border bg-white rounded-md">
                <p className="text-sm  font-medium mb-2">Hidden Products</p>
                {hiddenCards.sort((a,b)=>mappings[a.label]?.label.localeCompare(mappings[b.label]?.label)).map((elem) => {
                  return (
                    <div className="mb-2">
                      <Card item={elem} isAdd={true} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPageV2;
