import React, { useEffect, useState } from "react";
import { FaSpinner, FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";
import Preview from "./components/preview";
import { MdCheckCircle, MdHourglassEmpty, MdHourglassFull } from "react-icons/md";
import Select from "react-select";

const ActivationEmailSender = () => {
  const [formData, setFormData] = useState({
    email: "",
    confirm_email: "",
    org_name: "",
    first_name: "",
    last_name: "",
    force_email: true,
    expiry_date:""
  });
  const [steps, setSteps] = useState(0);
  const [error, setError] = useState("");
  const [accountType, setAccountType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading,setIsLoading] = useState(false)

  const [isOrgLoading,setOrgLoading] = useState(false);
  const [organisation,setAllOrganisation] = useState([]);

  useEffect(() => {
    if (formData.confirm_email !== formData.email) {
      setError("Email doesn't match");
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      setError("");
    }
  }, [formData]);

  const axios = useAxiosInstance();


  function formatDate(inputDate) {
    const originalDate = new Date(inputDate);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = originalDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  useEffect(() => {
    setOrgLoading(true);
    axios
      .get("admin/organisations/")
      .then((res) => {
        setAllOrganisation(res.data.organisations);
        setOrgLoading(false)
        })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting orgs");
        setOrgLoading(false);
      });
  }, []);

  const handleSubmit = () => {
    setIsLoading(true)
    let data = {};
    let expiry_date = formData.expiry_date;
    if(expiry_date!==""){
        data = {...formData,expiry_date:formatDate(expiry_date)}
    }else{
       data={...formData}
    }

    axios
      .post("/accounts/account_create_trial/", data)
      .then((res) => {
        setIsLoading(false)
        toast.success('Successfully Send')
        setSteps(0)
        setFormData({
          email: "",
          confirm_email: "",
          org_name: "",
          first_name: "",
          last_name: "",
          force_email: true,
          expiry_date:""
        })
        console.log(data);
        console.log(res.data);
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error(err.response.data.error)
        console.log(data);
      });
  };

  return (
    <div className="">
      <div className="flex justify-center">
        <div className="w-[676px] flex items-center mt-10">
          <div className="w-16 flex flex-col justify-center items-center">
            {steps === 0 ? <MdHourglassEmpty /> : <MdCheckCircle />}
            <div className="text-xs">Step 1</div>
          </div>
          <div
            className={`w-full h-1 ${
              steps === 0 ? "bg-slate-300" : "bg-black"
            }`}
          ></div>
          <div className="w-16 flex flex-col justify-center items-center">
            {steps === 0 ? <MdHourglassEmpty /> : <MdHourglassFull />}
            <div className="text-xs">Preview</div>
          </div>
        </div>
      </div>
      <div
        className={`w-full justify-center p-5  ${"items-start "} flex`}
      >
        {steps === 0 ? (
          <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
            <p className="text-2xl font-semibold mb-4">Activation Email Trial</p>

            <div className="text-sm font-semibold mb-2">Email</div>
            <Input
              value={formData.email}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value?.toLowerCase() });
              }}
              placeholder="Enter the email"
            />

            <div className="mt-4">
              {error && <p className="text-red-500">{error}</p>}
              <div className="text-sm font-semibold mb-2 ">Confirm Email</div>
              <Input
                value={formData.confirm_email}
                onChange={(e) => {
                  setFormData({ ...formData, confirm_email: e.target.value?.toLowerCase() });
                }}
                placeholder="Enter the confirm email"
              />
            </div>
            <div className=" mt-4 grid grid-cols-2 gap-2">
              <div>
                <div className="text-sm font-semibold mb-2"> First Name</div>
                <Input
                  value={formData.first_name}
                  onChange={(e) => {
                    setFormData({ ...formData, first_name: e.target.value });
                  }}
                  placeholder="Enter the first name"
                />
              </div>
              <div>
                <div className="text-sm font-semibold mb-2"> Last Name</div>
                <Input
                  value={formData.last_name}
                  onChange={(e) => {
                    setFormData({ ...formData, last_name: e.target.value });
                  }}
                  placeholder="Enter the last name"
                />
              </div>
            </div>
            {/* <div className="text-sm font-semibold mb-2 mt-4">
              Organization Name
            </div>
            <Input
              value={formData.org_name}
              onChange={(e) => {
                setFormData({ ...formData, org_name: e.target.value });
              }}
              placeholder="Enter the email"
            /> */}
            <div className="text-sm font-semibold mb-2 mt-4">Org name : {formData.org_name}</div>
                <Select
                  isLoading={isOrgLoading}
                //   placeholder={`${formData.user_profile_data?.org_name}`}
                  className="w-full"
                  options={organisation.map(elem=>({label:elem.name,value:elem.name}))}
                  onChange={(e) => {
                    setFormData({ ...formData, org_name: e.value});
                  }}
              />

            <div className="text-sm font-semibold mb-2 mt-4">Force Email</div>
            <select
              value={formData.force_email}
              onChange={(e) => {
                setFormData({...formData,force_email:e.target.value});
              }}
              className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 "
              placeholder="Enter your password"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>

            <div className="text-sm font-semibold mb-2 mt-4">
              Expiry Date<span className="text-[10px] ml-1">(optional)</span>
            </div>
            <Input
              value={formData.expiry_date}
              type={'date'}
              onChange={(e) => {
                setFormData({ ...formData, expiry_date: e.target.value });
              }}
              placeholder="Enter the email"
            /> 

            <button
              className="bg-black disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white text-white py-3 hover:shadow-lg  text-center w-full rounded-lg mt-4"
              type="button"
              disabled={
                isDisabled ||
                formData.confirm_email === "" ||
                formData.email === "" ||
                formData.first_name === "" ||
                formData.last_name === "" ||
                formData.org_name === "" ||
                formData.force_email === ""
              }
              onClick={() => {
                setSteps(1);
              }}
            >
              Next
            </button>
          </div>
        ) : (
          <Preview formData={formData} handleSubmit={handleSubmit} setSteps={setSteps} accountType={accountType} isLoading={isLoading}/>
        )}
      </div>
    </div>
  );
};

export default ActivationEmailSender;
