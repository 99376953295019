import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";

const AllConfigs = () => {
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  const [userObjs, setUserObjs] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [selectedOrgs, setSelectedOrgs] = useState([]);

  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    setIsUserLoading(true);
    axiosInstance
      .post("/newsletter/all_configs/",{org_name:selectedOrgs})
      .then((res) => {
        setIsUserLoading(false);
        setUserObjs(res.data.data || []);
        setOrgs(res.data.all_orgs);
      })
      .catch((err) => {
        setIsUserLoading(false);
      });
  }, [selectedOrgs]);

  return (
    <div className="h-screen overflow-y-auto">
      <p className="text-base font-semibold  my-2 px-2 py-2 bg-white rounded-lg border">
        All Configurations
      </p>
      {isUserLoading ? (
        <>Loading...</>
      ) : (
        <div>
          <p className="text-base font-semibold">Select Orgs</p>
          <div className="overflow-x-auto max-h-[400px] overflow-y-auto mt-4 border rounded-lg">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">Select</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Org name</th>
              </thead>
              <tbody className="divide-y">
                {orgs?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-normal text-gray-900">
                        <input 
                        onClick={()=>{
                            if(selectedOrgs.includes(s)){
                                setSelectedOrgs(prev=>prev.filter(it=>it!=s))
                            }else{
                                setSelectedOrgs(prev=>[...prev,s])
                            }
                        }}
                        checked={selectedOrgs.includes(s)}
                        type="checkbox" />
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 font-normal text-gray-900">
                        {s}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="overflow-x-auto max-h-[800px] overflow-y-auto mt-4 border rounded-lg">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">First Name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Last Name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Org name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Username</th>
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
              </thead>
              <tbody className="divide-y">
                {userObjs?.map((s, index) => {
                  return (
                    <tr key={index} className="bg-white odd:bg-gray-50">
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {s.first_name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.last_name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.org_name}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                        {s.username}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2  text-blue-500">
                        <button
                          className="bg-black text-white text-sm px-2 py-1 rounded-lg"
                          onClick={() =>
                            navigate(
                              "/newsletter-config/?username=" + s.username
                            )
                          }
                        >
                          View/Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllConfigs;
