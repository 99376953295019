import React, { useState } from 'react'
import OpenAI from 'openai';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';

const Playground = () => {

    const client = new OpenAI({
    apiKey: "sk-svcacct-l_T9q1ByWYC4wf86Nvs9-OjqOcO0mguy-hLDWT4ModBX4C67PWzFwGfTfx-8IAp9yECT3BlbkFJD04YHn4W8pn2LVVOa9JdaRBJuy7s3dOcgBk2HtRPqeUeKjLa0GFiUyNlUWamWs_6ydgA",dangerouslyAllowBrowser: true
});
    const [prompt,setPrompt] = useState("");
    const [model,setModel] = useState("");
    const [isLoading, setIsLoading] = useState("")
    const [answer, setAnswer] =useState('');  
    const make_ai_call=async()=>{
        setIsLoading(true)
        await client.chat.completions.create({
            messages: [{ role: 'user', content: prompt }],
            model: model,
          }).then(res=>{
            setIsLoading(false)
            setAnswer(res.choices[0].message.content)
            console.log(res)
          })
    } 
  return (
    <div>
        <div className='mr-4  p-3 my-5 border rounded-lg bg-white'>
        <p className='text-2xl text-black font-medium mb-4'>Playground</p>

        <p className='text-sm font-semibold text-black mb-2'>Prompt</p>
        <textarea 
            value={prompt}
            onChange={e=>{setPrompt(e.target.value)}}
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
        />

        <p className='text-sm font-semibold mt-4 text-black mb-2'>Model</p>

        <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={model}
            onChange={(e) => {
                setModel(e.target.value);
            }}
            placeholder="Enter model"
          >
           {['','gpt-4o','gpt-4o-mini','o1-mini'].map(elem=>{return <option key={elem} value={elem}>{elem==''?'choose':elem}</option>})} 
          </select>

          <button
            disabled={!prompt || !model || isLoading}
            className='bg-black px-4 py-2 text-white font-medium rounded-lg mt-4 disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white'
            onClick={()=>{make_ai_call()}}  
            >
                Fire
          </button>
          <button
          className='bg-black px-4 ml-2 py-2 text-white font-medium rounded-lg mt-4 disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white'
          onClick={()=>{
            setPrompt('')
            setModel('')
            setAnswer('')
          }}      
          >Reset</button>
            {!isLoading ?
            <>
            <div className='flex items-center justify-between'><p className='text-sm font-medium mt-4 mb-2'>Response</p>
            <button
            onClick={()=>{
                toast.info('Copied Successfully!')
                navigator.clipboard.writeText(answer)
            }}
            ><FaCopy/></button>
            </div>
            <div className='p-3 bg-gray-200 rounded-xl'>
            {answer}
            </div>
            </> :<p className='mt-3'>Loading....</p>}
      </div>      
    </div>
  )
}

export default Playground