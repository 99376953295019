import { ArrowDownward, Delete } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import Input from "../../components/input";
import useAxiosInstance from "../../hooks/useAxios";
import { useDebounce } from "use-debounce";
import { ModalComp } from "../../components/Modal";
import { Spinner } from "flowbite-react";
import { useSearchParams } from "react-router-dom";
import { Collapse } from "@mui/material";
import { FaArrowDown, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { toast } from "react-toastify";

const NewsletterConfiguration = () => {
  const axiosInstance = useAxiosInstance();
  const [themes, setThemes] = useState([]);
  const [newsConfigLoading, setNewsConfigLoading] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const username = searchParams.get("username");

  const [metaCompanySearchForPermalink, setMetaCompanySearchForPermalink] =
    useState("");
  const [metaCompanySearchResults, setMetaCompanySearchResults] = useState([]);
  const [metaPermalinkQuery] = useDebounce(metaCompanySearchForPermalink, 1000);
  const [metaPeramlinkResultsLoading, setMetaPermalinkResultsLoading] =
    useState(false);
  const [isMetaPermalinkShowMoreOn, setIsMetaPermalinkShowMoreButtonOn] =
    useState(false);

  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [addIndustryModal, setAddIndustryModal] = useState(false);
  const [industry, setIndustry] = useState("");
  const [themeId, setthemeId] = useState("");

  const [isThemeCollapsed, setIsThemeCollapsed] = useState(true);
  const [isConfigPageCollapsed, setIsConfigPageCollapsed] = useState(false);

  const [frequency_of_newsletter, setfrequency_of_newsletter] = useState(0); 
  const [dt_lastRun, setDtLastRun] = useState('');

  const [meta, setMeta] = useState({
    analysis_period: 0,
    blocked_sources: [],
    day_of_newsletter: 0,
    start_date_newsletter: "2024-09-20",
    whitelist_sources: [],
    cc: [],
  });

  const [IsXLSXDownloading ,setIsXLSXDownloading] = useState(false);

  useEffect(() => {
    if (metaPermalinkQuery !== "") {
      setMetaPermalinkResultsLoading(true);
      axiosInstance
        .post("/assets/get_company_list/", {
          company_type: "all",
          name: metaPermalinkQuery,
          search_by: "company name",
        })
        .then((res) => {
          setIsMetaPermalinkShowMoreButtonOn(true);
          setMetaCompanySearchResults(res.data.data);
          setMetaPermalinkResultsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setMetaPermalinkResultsLoading(false);
        });
    }
  }, [metaPermalinkQuery]);

  useEffect(() => {
    setNewsConfigLoading(true);
    axiosInstance
      .post("/newsletter/get_newsletter_settings_admin/", { email: username })
      .then((res) => {
        setNewsConfigLoading(false);
        setThemes(res.data.data.themes || []);
        setfrequency_of_newsletter(res.data.data.frequency_of_newsletter);
        setMeta(res.data.data.meta);
		setDtLastRun(res.data.data.dt_lastRun)
      })
      .catch((err) => {
        setNewsConfigLoading(false);
      });
  }, [username]);

  const [previewModal, setPreviewModal] = useState(false)

  const handleSave=()=>{
	setIsSaving(true);
    axiosInstance
      .post("/newsletter/configure_newsletter_admin/", {       
        "email":username,
        "frequency_of_newsletter": frequency_of_newsletter,
        "auto_add_company_and_industry": true,
        "auto_add_industry": true,
        "on_email_and_plaform": true,
        "only_platform": false,
		"dt_lastRun":dt_lastRun,
        "company_level_attributes": [
            "FUNDRAISE AND VALUATION",
            "MERGERS AND ACQUISITIONS",
            "PRODUCT RELATED",
            "PARTNERSHIPS",
            "EXECUTIVE APPOINTMENT AND CHANGES",
            "OFFICE OR GEOGRAPHY EXPANSION",
            "AWARDS AND RECOGNITIONS",
            "VENTURE INVESTMENTS",
            "LEADERSHIP INTERVIEW"
        ],
        "industry_level_attributes": [
            "Fundraise/valuation/Startup funding",
            "Mergers and Acquisitions/Investments/Grants",
            "Innovation/disruption",
            "Company deep-dive/ company product launches/ leadership interview",
            "Partnerships",
            "Regulatory"
        ],
        "themes": themes,
        "meta": meta
    })
      .then((res) => {
		if (res.data.status == 'success'){
			toast.success('Successfull')
		}else{
			toast.error('Failed')
		}
        setIsSaving(false);
    	})
      .catch((err) => {
        setIsSaving(false);
      });
  }

  useEffect(()=>{
	if(JSON.stringify(meta.cc) == JSON.stringify([""])){
		setMeta(prev=>({...prev, cc:[]}))
	}
	if(JSON.stringify(meta.blocked_sources) == JSON.stringify([""])){
		setMeta(prev=>({...prev, blocked_sources:[]}))
	}
	if(JSON.stringify(meta.whitelist_sources) == JSON.stringify([""])){
		setMeta(prev=>({...prev, whitelist_sources:[]}))
	}
  },[meta.cc, meta.blocked_sources, meta.whitelist_sources])

  const handleXLSXDownload = async () => {
    setIsXLSXDownloading(true);
    let url = `/newsletter/export_config/`
    
    await axiosInstance(url, {
        method: 'POST',
        responseType: 'blob',
		data : {
			"username":username
		}
    })
        .then((response) => {
            setIsXLSXDownloading(false);
            const url = window.URL.createObjectURL(
                new Blob([response.data])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Themes.xlsx`
            );
            document.body.appendChild(link);
            link.click();
        })
        .catch((err) => {
            setIsXLSXDownloading(false);
            // NotificationService.success('Downloading Failed');
        });
};

  return (
    <div className="h-screen overflow-y-auto">
      <ModalComp
        width={600}
        open={addCompanyModal}
        onClose={() => {
          setAddCompanyModal(false);
        }}
      >
        <div className="p-4 rounded-xl bg-white h-[400px]  overflow-y-auto">
          <Input
            type={"text"}
            value={metaCompanySearchForPermalink}
            placeholder={"Enter company name"}
            onChange={(e) => {
              setMetaCompanySearchForPermalink(e.target.value);
            }}
          />
          <div className="overflow-x-auto h-[180px]  overflow-y-auto mt-4 border rounded-lg">
            <table className="w-full">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm">Action</th>
                <th className="px-2 py-1 text-gray-700 text-sm">permalink</th>
                <th className="px-2 py-1 text-gray-700 text-sm">name</th>
                <th className="px-2 py-1 text-gray-700 text-sm">
                  founded_year
                </th>
                <th className="px-2 py-1 text-gray-700 text-sm">ipo_status</th>
                <th className="px-2 py-1 text-gray-700 text-sm">country</th>
                <th className="px-2 py-1 w-[100px] text-gray-700 text-sm">
                  short_description
                </th>
              </thead>
              <tbody className="divide-y">
                {metaPeramlinkResultsLoading ? (
                  <>Loading...</>
                ) : (
                  <>
                    {metaCompanySearchResults.map((result, index) => {
                      return (
                        <tr key={index} className="bg-white odd:bg-gray-50">
                          <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                            <button
                              onClick={() => {
                                let all_themes = JSON.parse(
                                  JSON.stringify(themes)
                                );
                                let themeData = themes.find(
                                  (item) => item.id == themeId
                                );
                                themeData.company_watchlist = [
                                  ...themeData.company_watchlist,
                                  {
                                    permalink: result.permalink,
                                    name: result.name,
                                  },
                                ];
                                let newSet = [];
                                all_themes.forEach((element) => {
                                  if (element.id == themeId) {
                                    newSet.push(themeData);
                                  } else {
                                    newSet.push(element);
                                  }
                                });
                                setThemes(newSet);
                              }}
                              className="bg-black cursor-pointer rounded-sm px-2 py-1 text-xs text-white"
                            >
                              Add company
                            </button>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                            {result.permalink}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                            {result.name}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                            {result.founded_year}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                            {result.ipo_status}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                            {result.country}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2  text-gray-900">
                            {result.short_description}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
              {isMetaPermalinkShowMoreOn && (
                <button
                  disabled={metaPeramlinkResultsLoading}
                  className="px-2 py-1 text-sm rounded-lg disabled:cursor-not-allowed"
                  onClick={() => {
                    setIsMetaPermalinkShowMoreButtonOn(false);
                    setMetaPermalinkResultsLoading(true);
                    axiosInstance
                      .post("/assets/get_company_list/", {
                        company_type: "all",
                        name: metaPermalinkQuery,
                        search_by: "company name",
                        global: true,
                      })
                      .then((res) => {
                        setMetaCompanySearchResults(res.data.data);
                        setMetaPermalinkResultsLoading(false);
                      })
                      .catch((err) => {
                        console.log(err);
                        setMetaPermalinkResultsLoading(false);
                      });
                  }}
                >
                  {metaPeramlinkResultsLoading ? "Loading..." : "Show more"}
                </button>
              )}
            </table>
          </div>
        </div>
      </ModalComp>
      <ModalComp
        width={600}
        open={addIndustryModal}
        onClose={() => {
          setAddIndustryModal(false);
        }}
      >
        <div className="p-4 rounded-xl bg-white h-[400px]  overflow-y-auto">
          <Input
            type={"text"}
            value={industry}
            placeholder={"Enter company name"}
            onChange={(e) => {
              setIndustry(e.target.value);
            }}
          />
          <button
            onClick={() => {
              let all_themes = JSON.parse(JSON.stringify(themes));
              let themeData = themes.find((item) => item.id == themeId);
              themeData.industry_watchlist = [
                ...themeData.industry_watchlist,
                industry,
              ];
              let newSet = [];
              all_themes.forEach((element) => {
                if (element.id == themeId) {
                  newSet.push(themeData);
                } else {
                  newSet.push(element);
                }
              });
              setThemes(newSet);
              setAddIndustryModal(false);
              setIndustry("");
            }}
          >
            add industry
          </button>
        </div>
      </ModalComp>
	  <ModalComp
        width={600}
        open={previewModal}
        onClose={() => {
          setPreviewModal(false);
        }}
      >
        <div className="border rounded-xl bg-gray-50 px-3 py-2">
          {/* "overflow-y:scroll; max-width:400px; height:80px; border:0.5px solid lightgray; padding:5px; white-space:pre-wrap;" */}
          <pre
            style={{
              overflowY: "scroll",
              maxWidth: "100%",
              height: "400px",
              whiteSpace: "pre-wrap",
            }}
          >
            {JSON.stringify(meta, {}, 4)}
          </pre>
		  <button
		  disabled={isSaving} 
		  onClick={()=>{
			let confir = window.confirm('DO you want to save these changes ?')
			if (confir){
				handleSave()
			}
		  }}
		  className="bg-black text-white px-2 py-1 rounded-md text-sm font-medium disabled:bg-white disabled:text-black">
			{isSaving ? "Loading":"Save"}
		</button>
        </div>
      </ModalComp>
      <p className="text-base font-semibold mx-4 my-2 px-2 py-2 bg-white rounded-lg border">
        Newsletter Configuration
      </p>
      {newsConfigLoading ? (
        <>
          <div className="h-[800px] w-full flex justify-center items-center">
            <Spinner />
          </div>
        </>
      ) : (
        <div className="mt-4">
          <p className="px-2 py-2 flex justify-between text-right mx-4 bg-white rounded-lg border text-sm font-semibold">
            <span>User Email: {username}</span>
            <p>
			<button
			disabled={IsXLSXDownloading}
			onClick={handleXLSXDownload}
			className="px-2" >{IsXLSXDownloading ? "Loading..." :"Export XLSX"}</button>
			
			<button
			onClick={()=>{
				setPreviewModal(true)
			}}
			className="px-2">Save</button>
			</p>
		  </p>

          <div className="px-2 py-2 mt-4 mx-4 bg-white rounded-lg border">
            <div className="flex justify-between">
              <p className="text-sm font-semibold">Themes</p>
              <div className="flex gap-1">
                <p
                  onClick={() => {
                    let id = uuidv4();
                    let newThemeObj = {
                      id: id,
                      name: "",
                      isExpand: false,
                      isEditingName: true,
                      industry_watchlist: [],
                      company_watchlist: [],
                      time_saved: `${new Date()}`,
                      pos_counter: themes.length + 1,
                      selectedWatchList: "Company tag",
                      isFocus: true,
                    };
                    setThemes((prev) => [...prev, newThemeObj]);
                  }}
                  className="text-xs px-2 bg-black rounded text-white py-1 font-semibold"
                >
                  Add Theme
                </p>
                <button
                  onClick={() => {
                    setIsThemeCollapsed((prev) => !prev);
                  }}
                >
                  {!isThemeCollapsed ? <FaChevronDown /> : <FaChevronUp />}
                </button>
              </div>
            </div>

            <Collapse in={isThemeCollapsed}>
              <div className="mt-3">
                {themes
                  .sort((a, b) => {
                    return a.time_saved - b.time_saved;
                  })
                  .map((theme) => {
                    return (
                      <div className="mb-3 bg-slate-200 rounded px-2 py-2">
                        <div className="flex justify-between">
                          {/* <p className="text-sm underline font-semibold">{theme.name}</p> */}
                          <input
                            value={theme.name}
                            onChange={(e) => {
                              let all_themes = JSON.parse(
                                JSON.stringify(themes)
                              );
                              let themeData = themes.find(
                                (item) => item.id == theme.id
                              );
                              themeData["name"] = e.target.value;
                              themeData["intermidiate_name"] = e.target.value;
                              let newSet = [];
                              all_themes.forEach((element) => {
                                if (element.id == theme.id) {
                                  newSet.push(themeData);
                                } else {
                                  newSet.push(element);
                                }
                              });
                              setThemes(newSet);
                            }}
                          />
                          <div className="flex gap-x-1">
                            {theme?.isInActive != true ? (
                              <p
                                onClick={() => {
                                  let all_themes = JSON.parse(
                                    JSON.stringify(themes)
                                  );
                                  let themeData = themes.find(
                                    (item) => item.id == theme.id
                                  );
                                  theme["isInActive"] = true;
                                  let newSet = [];
                                  all_themes.forEach((element) => {
                                    if (element.id == theme.id) {
                                      newSet.push(themeData);
                                    } else {
                                      newSet.push(element);
                                    }
                                  });
                                  setThemes(newSet);
                                }}
                                className="text-xs cursor-pointer px-2 bg-black rounded text-white py-1 font-semibold"
                              >
                                Mark Inactive
                              </p>
                            ) : (
                              <p
                                onClick={() => {
                                  let all_themes = JSON.parse(
                                    JSON.stringify(themes)
                                  );
                                  let themeData = themes.find(
                                    (item) => item.id == theme.id
                                  );
                                  theme["isInActive"] = false;
                                  let newSet = [];
                                  all_themes.forEach((element) => {
                                    if (element.id == theme.id) {
                                      newSet.push(themeData);
                                    } else {
                                      newSet.push(element);
                                    }
                                  });
                                  setThemes(newSet);
                                }}
                                className="text-xs cursor-pointer px-2 bg-black rounded text-white py-1 font-semibold"
                              >
                                Mark active
                              </p>
                            )}
                            <p
                              onClick={() => {
                                setAddCompanyModal(true);
                                setthemeId(theme.id);
                              }}
                              className="text-xs cursor-pointer px-2 bg-black rounded text-white py-1 font-semibold"
                            >
                              Add Company
                            </p>
                            <p
                              onClick={() => {
                                setAddIndustryModal(true);
                                setthemeId(theme.id);
                              }}
                              className="text-xs cursor-pointer px-2 bg-black rounded text-white py-1 font-semibold"
                            >
                              Add Industry
                            </p>
                            <button
                              onClick={() => {
                                setThemes((prev) => {
                                  let updatePrev = JSON.parse(
                                    JSON.stringify(prev)
                                  );
                                  return updatePrev.filter(
                                    (item) => item.id != theme.id
                                  );
                                });
                              }}
                            >
                              <Delete className="cursor-pointer" />
                            </button>
                          </div>
                        </div>
                        <p className="text-sm font-semibold mt-3">
                          Company watchlist
                        </p>
                        <div className="overflow-x-auto h-[220px] overflow-y-auto min-w-full mt-4 border rounded-lg">
                          {theme.company_watchlist.length > 0 ? (
                            <table className="w-full ">
                              <thead className="border-b text-left">
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                  Logo
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                  Name
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                  Permalink
                                </th>
                                <th className="px-2 py-1 text-gray-700 text-sm">
                                  Action
                                </th>
                              </thead>
                              <tbody className="divide-y">
                                {theme.company_watchlist.map((comp_elem) => {
                                  return (
                                    <tr>
                                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                        <img
                                          className="h-8"
                                          src={`https://wklogo.blob.core.windows.net/logos-thumbnail/${comp_elem.permalink}.png`}
                                        />
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                        {comp_elem.name}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                        {comp_elem.permalink}
                                      </td>
                                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                        <button
                                          onClick={() => {
                                            let all_themes = JSON.parse(
                                              JSON.stringify(themes)
                                            );
                                            let themeData = themes.find(
                                              (item) => item.id == theme.id
                                            );
                                            themeData.company_watchlist =
                                              themeData.company_watchlist.filter(
                                                (it) =>
                                                  it.permalink !=
                                                  comp_elem.permalink
                                              );
                                            let newSet = [];
                                            all_themes.forEach((element) => {
                                              if (element.id == theme.id) {
                                                newSet.push(themeData);
                                              } else {
                                                newSet.push(element);
                                              }
                                            });
                                            setThemes(newSet);
                                            setAddCompanyModal(false);
                                          }}
                                        >
                                          <Delete />
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div className="flex h-[80px] w-full text-sm justify-center items-center">
                              No companies added
                            </div>
                          )}
                        </div>

                        <p className="text-sm font-semibold mt-3">
                          Industry watchlist
                        </p>
                        {theme.industry_watchlist.length > 0 ? (
                          <table className="w-full ">
                            <thead className="border-b text-left">
                              <th className="px-2 py-1 text-gray-700 text-sm">
                                Name
                              </th>
                              <th className="px-2 py-1 text-gray-700 text-sm">
                                Action
                              </th>
                            </thead>
                            <tbody className="divide-y">
                              {theme.industry_watchlist.map((comp_elem) => {
                                return (
                                  <tr>
                                    <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                      {comp_elem}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                      <button
                                        onClick={() => {
                                          let all_themes = JSON.parse(
                                            JSON.stringify(themes)
                                          );
                                          let themeData = themes.find(
                                            (item) => item.id == theme.id
                                          );
                                          themeData.industry_watchlist =
                                            themeData.industry_watchlist.filter(
                                              (it) => it != comp_elem
                                            );
                                          let newSet = [];
                                          all_themes.forEach((element) => {
                                            if (element.id == theme.id) {
                                              newSet.push(themeData);
                                            } else {
                                              newSet.push(element);
                                            }
                                          });
                                          setThemes(newSet);
                                        }}
                                      >
                                        <Delete />
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <div className="flex h-[80px] text-sm w-full justify-center items-center">
                            No industries added
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </Collapse>
          </div>
          <div className="px-2 py-2 mt-3 mx-4 bg-white rounded-lg border">
            <div className="flex justify-between">
              <p className="text-sm font-semibold">Config Settings</p>
              <button
                onClick={() => {
                  setIsConfigPageCollapsed((prev) => !prev);
                }}
              >
                {!isConfigPageCollapsed ? <FaChevronDown /> : <FaChevronUp />}
              </button>
            </div>

            <Collapse in={isConfigPageCollapsed}>
              <div className="mt-3">
                <div className="p-2 bg-slate-100 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
                    Frequency of newsletter
                  </p>
                  <select
                    className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
                    value={frequency_of_newsletter}
                    onChange={(e) => {
                      setfrequency_of_newsletter(Number(e.target.value));
                    }}
                    placeholder="Enter frequency of newsletter"
                  >
                    {/* <option value={''}>choose</option> */}
                    <option value={1}>Daily</option>
                    <option value={7}>1 Weeks</option>
                    <option value={14}>2 Weeks</option>
                    <option value={28}>4 Weeks</option>
                  </select>
                </div>

				<div className="p-2 bg-slate-100 mt-2 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
                    Analysis Period
                  </p>
                  <select
                    className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
                    value={meta.analysis_period}
                    onChange={(e) => {
                      setMeta(prev=>({...prev, analysis_period:Number(e.target.value)}));
                    }}
                    placeholder="Enter analysis period"
                  >
                    {/* <option value={''}>choose</option> */}
                    <option value={1}>24 hours</option>
					<option value={2}>48 hours</option>
                    <option value={7}>1 Weeks</option>
                    <option value={14}>2 Weeks</option>
                    <option value={28}>4 Weeks</option>
                  </select>
                </div>

				<div className="p-2 mt-2 bg-slate-100 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
				  	Day of newsletter
                  </p>
                  <select
                    className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
                    value={meta.day_of_newsletter}
                    onChange={(e) => {
                      setMeta(prev=>({...prev, day_of_newsletter:Number(e.target.value)}));
                    }}
                    placeholder="Enter day of newsletter"
                  >
                    <option value={0}>Sunday</option>
					<option value={1}>Monday</option>
                    <option value={2}>Tuesday</option>
                    <option value={3}>Wednesday</option>
                    <option value={4}>Thursday</option>
					<option value={5}>Friday</option>
					<option value={6}>Saturday</option>
                  </select>
                </div>

				<div className="p-2 mt-2 bg-slate-100 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
				  	Start date of newsletter
                  </p>
                  <Input
                    value={meta.start_date_newsletter}
                    onChange={(e) => {
                      setMeta(prev=>({...prev, start_date_newsletter:e.target.value}));
                    }}
                    placeholder="Enter start date of newsletter"
					type={'date'}
				  />
                </div>
				
				<div className="p-2 mt-2 bg-slate-100 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
				  	Last run
                  </p>
                  <Input
                    value={dt_lastRun}
                    onChange={(e) => {
                      setDtLastRun(e.target.value);
                    }}
                    placeholder="Enter start date of newsletter"
					type={'date'}
				  />
                </div>

				<div className="p-2 mt-2 bg-slate-100 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
				  	Blocked Sources(comma seprated)
                  </p>
                  <Input
                    value={(meta.blocked_sources || []).join(',')}
                    onChange={(e) => {
                      setMeta(prev=>({...prev, blocked_sources:e.target.value.split(',')}));
                    }}
                    placeholder="Enter Blocked Sources"
					
				  />
                </div>

				<div className="p-2 mt-2 bg-slate-100 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
				  	Whitelist Sources(comma seprated)
                  </p>
                  <Input
                    value={(meta.whitelist_sources || []).join(',')}
                    onChange={(e) => {
                      setMeta(prev=>({...prev, whitelist_sources:e.target.value.split(',')}));
                    }}
                    placeholder="Enter whitelist Sources"
					
				  />
                </div>

				<div className="p-2 mt-2 bg-slate-100 rounded-md border">
                  <p className="mb-2 text-sm font-medium">
				  	CC(comma seprated)
                  </p>
                  <Input
                    value={(meta.cc || []).join(',')}
                    onChange={(e) => {
                      setMeta(prev=>({...prev, cc:e.target.value.split(',')}));
                    }}
                    placeholder="Enter cc"
					
				  />
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsletterConfiguration;
