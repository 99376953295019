import React, { useState } from "react";
import Input from "../../components/input";
import { toast } from "react-toastify";
import useAxiosInstance from "../../hooks/useAxios";

const ReportMigration = () => {
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    report_id: 0,
    validation_title: "",
    tranfer_to: "",
    organisation: "",
  });

  return (
    <div className="">
      <div className={` w-full justify-center p-5 ${"items-start "} flex`}>
        <div className="w-[676px] p-3 mt-5 border rounded-lg bg-white">
          <p className="text-2xl font-semibold mb-4">Migrate Report</p>
          <div className="text-sm font-semibold mb-2">Report ID*</div>
          <Input
            value={formData.report_id}
            onChange={(e) => {
              setFormData({ ...formData, report_id: Number(e.target.value) });
            }}
            type={"number"}
            placeholder="Enter the report_id"
          />

          <div className="text-sm font-semibold mt-4 mb-2">
            Tranfer to username*
          </div>
          <Input
            value={formData.tranfer_to}
            onChange={(e) => {
              setFormData({ ...formData, tranfer_to: e.target.value });
            }}
            placeholder="Enter the tranfer_to"
          />

          <div className="text-sm font-semibold mt-4 mb-2">Valid title*</div>
          <Input
            value={formData.validation_title}
            onChange={(e) => {
              setFormData({ ...formData, validation_title: e.target.value });
            }}
            placeholder="Enter the validation_title"
          />

          <div className="text-sm font-semibold mt-4 mb-2">
            Organisation Name
          </div>
          <Input
            value={formData.organisation}
            onChange={(e) => {
              setFormData({ ...formData, organisation: e.target.value });
            }}
            placeholder="Enter the organisation"
          />

          <button
            disabled={isLoading}
            className="text-sm bg-black disabled:cursor-not-allowed text-white font-medium px-2 py-1 rounded-md mt-2"
            onClick={() => {
              setIsLoading(true);
              axiosInstance
                .post("/accounts/migrate_report/", formData)
                .then((res) => {
                  setIsLoading(false);
                  if (res.data.status == "success") {
                    toast.success(res.data.message);
                  } else {
                    toast.info(res.data.message);
                  }
                })
                .catch((err) => {
                  setIsLoading(false);
                  console.log(err?.response?.data?.message);
                });
            }}
          >
            {isLoading ? "migrating...." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ReportMigration;
