import React, { useEffect, useState } from "react";
import { MdEdit, MdFilter8 } from "react-icons/md";
import { ModalComp } from "../../components/Modal";
import Input from "../../components/input";
import { FaFilter } from "react-icons/fa";
import useAxiosInstance from "../../hooks/useAxios";
import { Spinner } from "flowbite-react";
import { toast } from "react-toastify";
import Select from "react-select";
import { listOfSubscription } from "../../App";
import { v4 as uuid } from "uuid";
import { useSearchParams } from "react-router-dom";

const Subscriptions = () => {
  const [tableData, setTableData] = React.useState([]);
  const axios = useAxiosInstance();
  const [oldArray,setOldArray] = useState({});
  const [isOrgLoading, setIsOrgLoading] = useState(false);
  const [allOrg, setAllOrg] = useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [orgName, setOrgName] = React.useState("Wokelo");
  const [orgNameToShow,setOrgNameToShow] = React.useState(""); 
  const [reportType, setReportType] = React.useState("");
  const [subscription, setSubscription] = React.useState([]);
  const [filterModal, setFilterModal] = React.useState(false);
  const [all_report_type_filter, set_all_report_type_filter] = React.useState(
    []
  );
  const [all__initial_report_type_filter, set_all__initial_report_type_filter] = React.useState(
    {}
  );
  const [docTemplates, setDocTemplates] = useState([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [toggleView,setToggleView] = useState('user_data');

  const setTableData_final = (tableData) =>{
      let newTableData = JSON.parse(JSON.stringify(tableData))
      newTableData.user_data.forEach(item=>{
        item['id'] = uuid()
      })
      newTableData.admin_data.forEach(item=>{
        item['id'] = uuid()
      })
      setOldArray(
        JSON.parse(JSON.stringify({
          user_data:newTableData.user_data,
          admin_data:newTableData.admin_data
        })
      ))
      setTableData(JSON.parse(JSON.stringify(newTableData)))
  }

  const GetTags = ({ id,toggleView }) => {
    let list = tableData[toggleView].find(item=>item?.id == id)?.value
    // list = list?.map((elem) => elem?.toLowerCase());
    if (list.length === 0 || list.includes("Hidden")) {
      return <p className="px-3 py-2 rounded-md bg-yellow-400">Hidden</p>;
    } else if (list.includes("Lock")) {
      return <p className="px-3 py-2 rounded-md bg-red-400">Locked</p>;
    } else {
      return <p className="px-3 py-2 rounded-md bg-green-400">Visible</p>;
    }
  };
 const set_initial_state =(res) =>{
  setTableData_final(res.data)
  setOrgNameToShow(res.data.org_name)
  setOrgName(res.data.org_name)
 }

  const [searchParams,setSearchParams] = useSearchParams()
  React.useEffect(()=>{
    console.log(searchParams)
    const userParamOrgName = searchParams.get('org_name')
    if(userParamOrgName){
      setOrgName(userParamOrgName)
    }
  },[])

  // React.useEffect(()=>{
  //   setSearchParams(param=>param.set('org_name',orgName))
  // },[orgName])

  React.useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    if(orgName!==""){
    axios
      .post("/accounts/sandbox_org_data_table_wise/", {
        filter_report_types:all_report_type_filter,
        org_name:orgName
      },{
        signal:controller.signal
      })
      .then((res) => {
        setIsLoading(false);
        set_initial_state(res);
        set_all__initial_report_type_filter(JSON.parse(JSON.stringify(res?.data?.all_filters|| [])))
        set_all_report_type_filter(Object.keys(res.data.all_filters))
        setDocTemplates(res.data.doc_templates)
        // let res_data = JSON.parse(JSON.stringify(res?.data))
        
      })
      .catch((err) => {
        // toast.error('Loading data failed')
        setIsLoading(false);
      });
      
      return () => {
        controller.abort();
      }; 
    }
  }, [orgName]);

  React.useEffect(()=>{
    console.log("new_",tableData)
  },[tableData])

  React.useEffect(()=>{
    console.log('initial ',oldArray)
  },[oldArray])
  
  const apply_filter = () => {
    setIsLoading(true);
    if(orgName!==""){
      axios
        .post("/accounts/sandbox_org_data_table_wise/", {
          filter_report_types:all_report_type_filter,
          org_name:orgName
        })
        .then((res) => {
          setIsLoading(false);
          set_initial_state(res);
          setFilterModal(false);
        })
        .catch((err) => {
          toast.error('Loading data failed')
          setIsLoading(false);
        });}
  };
  const addGivenItemToArray= (item,Array) =>{
    Array.push(item)
    return Array 
  }

  const isTwoArrayDifferent = (oldArray1,newArray1) => {
    if(oldArray1.length !== newArray1.length){
        return true
    }else{
      let isDifferent = false
      oldArray1.forEach(elem=>{
        if(!newArray1.includes(elem)){
          isDifferent = true
        }
      })
      newArray1.forEach(elem=>{
        if(!oldArray1.includes(elem)){
          isDifferent = true
        }
      })
      return isDifferent
    }
  }

  const handleSave = (toggleView) => {
    setIsSaving(true);
    let changedData_set = []
    console.log(toggleView)
    console.log(oldArray)
    oldArray[toggleView].forEach(elems=>{
      let oldArrayValue = elems.value
      let newArrayValue = tableData[toggleView].find(i=>i.id === elems.id).value
      if(isTwoArrayDifferent(oldArrayValue,newArrayValue)){
        changedData_set.push(tableData[toggleView].find(i=>i.id === elems.id))
      }
    })
    console.log('changed data',changedData_set)

    if(changedData_set.length > 0){
    toast.info('sending apis requests.....')
    }else{
      toast.info(`No changes detected in the ${toggleView == 'user_data' ? 'User view' : 'Admin view'} .....`)
    }
    Promise.all(changedData_set.map(async(elem)=>{
      let newElemValue = JSON.parse(JSON.stringify(elem.value || []))
      newElemValue = newElemValue.filter(i=>i!="")
      axios
      .post("/accounts/update_org_product/", {
        org_name: orgName,
        report_type: elem.label,
        subscription_status: newElemValue,
        modification_type: toggleView == "user_data"? "user" : "admin"
      })
      .then(() => {
        toast.success(`${orgName} for report_label ${elem.label} is done`)
      }).catch(err=>{
        toast.success(`${orgName} for report_label ${elem.label} is failed.`)
      });
    })).then(res=>{
      if(orgName!==""){
        setIsLoading(true);
        axios
          .post("/accounts/sandbox_org_data_table_wise/", {
            filter_report_types:all_report_type_filter,
            org_name:orgName
          })
          .then((res) => {
            setIsLoading(false);
            set_initial_state(res);
            // setOldArray(JSON.parse(JSON.stringify(res?.data?.data|| [])))
          })
          .catch((err) => {
            toast.error('Loading data failed')
            setIsLoading(false);
          });}
    }) 
  };
  useEffect(() => {
    setIsOrgLoading(true);
    axios
      .get("accounts/organisations/")
      .then((res) => {
        setAllOrg(
          res.data.organisations
        );
        // setOrgName(Object.keys(res.data.organisations)[0])
        setIsOrgLoading(false);
      })
      .catch((err) => {
        setIsOrgLoading(false);
        toast.error("Bad Network");
      });
  }, []);
  
  console.log(all__initial_report_type_filter)
  return (
    <>
      <ModalComp
        open={filterModal}
        onClose={() => {
          setFilterModal(false);
        }}
      >
        <div className="bg-white px-6 rounded-md max-h-[300px] overflow-y-auto">
          {Object.keys(all__initial_report_type_filter)?.map((elem) => {
            return (
              <div className="flex gap-x-2 mb-2 items-center">
                <p className="text-sm capitalize font-semibold">{all__initial_report_type_filter[elem].label}</p>
                <input
                  type="checkbox"
                  onClick={() => {
                    all_report_type_filter.map((subs) => subs).includes(elem)
                      ? set_all_report_type_filter((prev) =>
                          prev.filter((item) => item != elem)
                        )
                      : set_all_report_type_filter((prev) => [...prev, elem]);
                  }}
                  checked={all_report_type_filter
                    .map((elem) => elem)
                    .includes(elem)}
                />
              </div>
            );
          })}
          <button
            className="px-2 py-1 text-white rounded bg-black"
            onClick={() => {
              apply_filter();
            }}
          >
            Apply
          </button>
        </div>
      </ModalComp>
      {isLoading || isOrgLoading? (
        <div className="h-screen w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="min-h-screen overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center w-full">
              <div className="text-2xl font-semibold">Subscriptions</div>
              <div className="flex justify-between space-x-2">
              <select
              className="border rounded-lg px-3 py-2 focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
              value={toggleView}
              onChange={(e)=>{
                setToggleView(e.target.value)
              }}
              >
              <option value={'user_data'}>User view</option>
              <option value={'admin_data'}>Admin view</option>
            </select>
              <div className="border rounded-lg px-3 py-2">Org : {orgNameToShow}</div>
              <Select
              isLoading={isOrgLoading}
              className="w-[250px]"
              options={Object.keys(allOrg).map(elem=>({label:elem,value:elem}))}
              onChange={(e) => {
                setOrgName(e.value)
              }}
            />
            <button
                onClick={() => setFilterModal(true)}
                className="py-1 px-2 rounded-md bg-white border border-black"
              >
                <FaFilter className="" />
              </button>
              </div>
            </div>
            <div className="flex justify-between w-full items-center">
              <p className="text-base font-semibold mt-10">Selected Orgs : {orgName}</p>   
              <button
            // disabled={isLoading} 
            className='bg-black disabled:bg-[#B6b6b6] disabled:cursor-not-allowed disabled:text-white text-white py-2 px-2 hover:shadow-lg  text-center max-w-max rounded-lg mt-4'
            onClick={()=>{
               handleSave(toggleView)
            }}>Save</button> 
              </div>
              <div className="overflow-x-auto mt-4 border rounded-lg">

        <table className="w-full">
          <thead className="border-b text-left">
            <th className="px-2 py-1 text-gray-700 text-sm">Report type</th>
             {listOfSubscription.map(item=><th className="px-2 py-1 text-gray-700 text-sm">{item}</th>)} 
             <th className="px-2 py-1 text-gray-700 text-sm">PPT</th>
             <th className="px-2 py-1 text-gray-700 text-sm">DOCX</th>
              
          </thead>
          <tbody className="divide-y h-[60vh] overflow-y-auto">
            {tableData[toggleView]?.sort((a,b)=>{return a.label.localeCompare(b.label)}).map((s,index)=>{return <tr key={index} className="bg-white odd:bg-gray-50">
              <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                {all__initial_report_type_filter[s.label].label} <sub className="text-xs">({s.label})</sub> 
                <br/>
                <p className="text-xs mt-1">{all__initial_report_type_filter[s.label].description}</p>
              </td>
              {/* {s.value} */}
              {listOfSubscription.map(item=><td 
              title={item}
              className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                <input type={'checkbox'} checked={s.value.includes(item)} 
                  onClick={()=>{
                    let prevData = JSON.parse(JSON.stringify(tableData[toggleView]));
                    let perticularConfig = prevData.find(item=>item.id===s.id)
                    console.log(perticularConfig)
                    if(perticularConfig['value'].includes(item)){
                      perticularConfig['value'] = perticularConfig['value'].filter(it=>it!=item)
                    }else{
                      perticularConfig['value'] = [...perticularConfig['value'],item]
                    } 
                    console.log('perticularConfig',JSON.parse(JSON.stringify(perticularConfig)))
                    prevData = [...prevData.filter(item=>item.id!==s.id),perticularConfig]   
                        
                    let newTableData = JSON.parse(JSON.stringify(tableData))
                    newTableData[toggleView] = prevData
                    setTableData(newTableData)
                  }}
                />
              </td>)}
              <td className="py-1">
              <p className="text-xs">Selected :- {docTemplates.find(it=>it.id == s.pptx)?.label || 'not-selected'}</p>
                {<Select
                  className="w-full"
                  options={docTemplates.filter(it=>it.doc_type == 'pptx').map(elem=>({label:elem.label,value:elem.id}))}
                  onChange={(e) => {
                    console.log(e.value)
                    axios.post("/accounts/update_org_product/", {
                      "org_name":orgName,
                      "report_type":s.label,
                      "pptx":e.value
                    }).then(res=>toast.success(`${s.label} (${orgName}) for pptx is marked successfully`)).catch(err=>toast.error("Something went wrong!"))
                  }}
                />}</td>
              <td className="py-1">
              <p className="text-xs">Selected :- {docTemplates.find(it=>it.id == s.docx)?.label || 'not-selected'} </p>
              <Select
                  className="w-full"
                  options={docTemplates.filter(it=>it.doc_type == 'docx').map(elem=>({label:elem.label,value:elem.id}))}
                  onChange={(e) => {
                    console.log(e.value)
                    axios.post("/accounts/update_org_product/", {
                      "org_name":orgName,
                      "report_type":s.label,
                      "docx":e.value
                    }).then(res=>toast.success(`${s.label} (${orgName}) for docx is marked successfully`)).catch(err=>toast.error("Something went wrong!"))
                  }}
                />
              </td>
              <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                <GetTags id={s.id} toggleView={toggleView}/>
              </td>   
            </tr>})}	
          </tbody>
        </table>
           <div>
          
           </div>       
      </div>
       </div>
        </div>
      )}
    </>
  );
};

export default Subscriptions;