import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxios";
import {
  OpenInBrowser,
  OpenInNew,
  OpenInNewOffOutlined,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { MdEdit, MdSort } from "react-icons/md";
import { FaCross, FaSort, FaXbox } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Input from "../../components/input";
import { useDebounce } from "use-debounce";
import Select from "react-select";
import { ModalComp } from "../../components/Modal";

const CompanyRequests = () => {
  //useStates
  const [isLoading, setIsLoading] = useState(false);
  const axiosInstance = useAxiosInstance();
  const [data, setData] = useState([]);
  const [CompanyRequestsResponse, setCompanyRequestsResponse] = useState({});
  const [
    companyRequestPaginatedIntermidatePageNumber,
    setcompanyRequestPaginatedIntermidatePageNumber,
  ] = useState(1);
  const [
    companyRequestPaginatedPageNumber,
    setcompanyRequestPaginatedPageNumber,
  ] = useState(1);

  const [selectedIds, setSelectedIds] = useState([]);
  const [orgLoadings, setOrgLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [isOnceClicked, etIsOnceClicked] = useState(true);
  const [apiRetrigger, setApiRetrigger] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const authDetails = useSelector((state) => state.auth);
  const [migrationLoading, setMigrationLoading] = useState("");
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  // `/admin/get-org-users/?&pgsz=20&page=1org_ids=2`
  // `/admin/organisations/?user_id=&start_date=2023-12-15&end_date=2024-07-12&report_type_id=&status=&group=all&org_type=`
  const [org_id_to_details_mappnig, setOrg_id_to_details_mapping] = useState(
    {}
  );
  const [orgs, setOrgs] = useState({});
  const [selectedOrgList, setSelectedOrgList] = useState({});
  const [searchOrg, setSeachOrg] = useState("");

  //user menu states
  const [selectedUserList, setSelectedUserList] = useState({});
  const [userList, setUserList] = useState({});
  const [
    userPaginatedIntermidatePageNumber,
    setUserPaginatedIntermidatePageNumber,
  ] = useState(1);
  const [IsXLSXDownloading, setIsXLSXDownloading] = useState(false);
  const [userPaginatedPageNumber, setUserPaginatedPageNumber] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userQuery] = useDebounce(userSearch, 1000);
  //useNavigate
  const navigate = useNavigate();

  //functions
  const handleApiCall = (
    selectedIds,
    status,
    permalink = "",
    isUpdateApi = false
  ) => {
    setIsSaving(true);
    axiosInstance
      .post(
        isUpdateApi == false
          ? "/assets/update_company_requests_status/"
          : "/assets/update_company_request_permalink/",
        isUpdateApi
          ? {
              id: selectedIds,
              permalink: permalink,
            }
          : permalink == ""
          ? {
              ids: selectedIds,
              status: status,
            }
          : {
              ids: selectedIds,
              status: status,
              permalink: permalink,
            }
      )
      .then((res) => {
        toast.success("Updated");
        setIsSaving(false);
        setApiRetrigger((prev) => prev + 1);
      })
      .catch((err) => {
        setIsSaving(false);
        console.log(err);
      });
  };

  const handleSave = (key, updated_value, id) => {
    console.log(updated_value);

    setIsSaving(true);
    let meta_data = data.find((it) => it.id == id).meta || {};
    meta_data[key] = updated_value;
    axiosInstance
      .post("/assets/update_company_request_meta/", {
        id: id,
        meta: meta_data,
      })
      .then((res) => {
        toast.success("Updated");
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaving(false);
      });
  };

  const formateDate = (dateGiven) => {
    if (dateGiven !== "") {
      const date = new Date(dateGiven);
      const dateFormattedDate = `${date.getFullYear()}/${date.getMonth()}/${date.getDate()} , ${date.getHours()}:${date.getMinutes()}`;
      return dateFormattedDate;
    } else {
      return "";
    }
  };

  const todays = () => {
    const date = new Date();
    const dateFormattedDate = `${date.getFullYear()}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
    return dateFormattedDate;
  };

  const handleMigrations = async (
    permalink,
    fromEnv = "source",
    toEnv = "prod"
  ) => {
    let confirm = window.confirm(
      `Are you sure you want to migrate ${permalink} from ${fromEnv} to ${toEnv} env ?`
    );
    if (confirm) {
      setMigrationLoading(true);

      toast.info("Searching for permalink in source env");
      let company_data = {};
      const config = {
        source: {
          endpoint: process.env.REACT_APP_SOURCE_API_ENDPOINT,
        },
        test: {
          endpoint: process.env.REACT_APP_TEST_API_ENDPOINT,
        },
        prod: {
          endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
        },
      };

      await axios
        .post(config[fromEnv]?.endpoint + "/assets/do_company_exists/", {
          permalink: permalink,
          email: authDetails.username,
        })
        .then(async (res) => {
          if (res.data.do_exists) {
            toast.success("Permalink search Successfully");
            company_data = res.data.compnay_data;

            if (Object.keys(company_data).length > 0) {
              let permalink_absent = false;
              toast.info("checking if permalink if absent in the " + toEnv);

              //REMOVE THIS 'b' before sending it to prod
              await axios
                .post(config[toEnv]?.endpoint + "/assets/do_company_exists/", {
                  permalink: permalink,
                  email: authDetails.username,
                })
                .then(async (res) => {
                  if (res.data.do_exists) {
                    toast.error("Permalink found in this env " + toEnv);
                    setMigrationLoading(false);
                    permalink_absent = true;
                  } else {
                    toast.success(
                      "Permalink absent in " + toEnv,
                      ". Migration begins."
                    );

                    await axios
                      .post(
                        config[toEnv]?.endpoint + "/assets/create_company/",
                        { ...company_data, email: authDetails.username }
                      )
                      .then((res) => {
                        setMigrationLoading(false);
                        if (res.data.status === "success") {
                          toast.success(
                            "sucessfully added. Migration successfull"
                          );
                        } else {
                          toast.error(
                            "This company already exists. Migration Failed."
                          );
                        }
                      })
                      .catch((err) => {
                        console.log("err1", err);
                        setMigrationLoading(false);
                        toast.error("Something went wrong!");
                      });
                  }
                })
                .catch((err) => {
                  console.log("err2", err);
                  setMigrationLoading(false);
                  toast.error("Something went wrong!");
                });
            }
          } else {
            setMigrationLoading(false);
            toast.error("cannot find Permalink");
          }
        })
        .catch((err) => {
          setMigrationLoading(false);
          console.log("err2", err);
          toast.error("Something went wrong!");
        });
    }
  };

  const getOrgSearchData = (searchOrg, orgs) => {
    if (searchOrg === "") {
      return orgs;
    } else {
      return orgs?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchOrg?.toLowerCase())
      );
    }
  };

  const [filterTimeRange, setFilterTimeRange] = useState({
    startDate: "1900-01-01",
    endDate: todays(),
  });

  const getOrgImage = (img, name) => {
    if (img === null) {
      return `https://wklogo.blob.core.windows.net/logos-small/${name[0]?.toUpperCase()}.png`;
    } else {
      return img;
    }
  };

  const handleXLSXDownload = async () => {
    setIsXLSXDownloading(true);
    let new_orgs_ids = Object.keys(selectedOrgList).map((elem) => Number(elem));
    let new_user_ids = Object.keys(selectedUserList).map((elem) =>
      Number(elem)
    );
    let url = `/assets/export_company_requests/?page=${companyRequestPaginatedPageNumber}&org_ids=${new_orgs_ids}&users_ids=${new_user_ids}&start_date=${filterTimeRange.startDate}&end_date=${filterTimeRange.endDate}&pgsz=999999999999`;

    await axiosInstance(url, {
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        setIsXLSXDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `List of company_Reports.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setIsXLSXDownloading(false);
        // NotificationService.success('Downloading Failed');
      });
  };

  //   useEffects
  useEffect(() => {
    setIsLoading(true);
    let new_orgs_ids = Object.keys(selectedOrgList).map((elem) => Number(elem));
    let new_user_ids = Object.keys(selectedUserList).map((elem) =>
      Number(elem)
    );
    const controller = new AbortController();
    axiosInstance
      .get(
        `/assets/get_company_requests/?page=${companyRequestPaginatedPageNumber}&org_ids=${new_orgs_ids}&users_ids=${new_user_ids}&start_date=${filterTimeRange.startDate}&end_date=${filterTimeRange.endDate}`,
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        res?.data?.company_requests?.forEach((elem) => {
          elem["isEnteringPermalink"] = false;
          elem["isEditingPermalink"] = false;
        });
        setData(res.data.company_requests);
        setcompanyRequestPaginatedIntermidatePageNumber(res.data.current_page);
        setCompanyRequestsResponse(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went wrong");
        // setIsLoading(false);
      });
  }, [apiRetrigger, companyRequestPaginatedPageNumber]);

  useEffect(() => {
    setIsUserListLoading(true);
    let new_orgs_ids = Object.keys(selectedOrgList).map((elem) => Number(elem));
    const controller = new AbortController();
    axiosInstance
      .get(
        `/admin/get-org-users/?&pgsz=20&page=${userPaginatedPageNumber}&org_ids=${new_orgs_ids}&query=${userQuery}`,
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setUserList(res.data);
        setIsUserListLoading(false);
        setUserPaginatedIntermidatePageNumber(res.data.current_page);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting users");
        // setIsUserListLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [userPaginatedPageNumber, userQuery, selectedOrgList]);

  useEffect(() => {
    setUserPaginatedPageNumber(1);
  }, [userQuery, selectedOrgList]);

  useEffect(() => {
    setcompanyRequestPaginatedPageNumber(1);
  }, [selectedOrgList]);

  useEffect(() => {
    setOrgLoading(true);
    axiosInstance
      .get("admin/organisations/")
      .then((res) => {
        setOrgs(res.data);
        let new_mapping = {};
        let all_orgs = res?.data?.organisations;
        all_orgs.forEach((org) => {
          new_mapping[org.org_id] = org;
        });
        setOrgLoading(false);
        // setcompanyRequestPaginatedPageNumber(1)
        setOrg_id_to_details_mapping(new_mapping);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting users");
        setOrgLoading(false);
      });
  }, []);

  const [userListForSeletion, setUserListForSeletion] = useState([]);
  const [userListForSeletionLoading, setUserListForSeletionLoading] =
    useState(false);
  const [openReportMigrationModal, setReportMigrationModal] = useState(false);

  useEffect(() => {
    setUserListForSeletionLoading(true);
    const controller = new AbortController();
    axiosInstance
      .get(
        `/admin/get-org-users/?pgsz=9999999999999999999999999&page=1&org_type=internal`,
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setUserListForSeletion(res.data.users);
        setUserListForSeletionLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting users");
        // setIsUserListLoading(false);
      });
  }, []);

  const [reportMigartionReportId, setReportMigationReportId] = useState("");
  const [reportMigartionTitle, setReportMigartionTitle] = useState("");
  const [reportMigartionMigrateTo, setReportMigartionMigrateTo] = useState("");
  const [reportMigrationLoading, setReportMigrationLoading] = useState("");
  const [selectedReporMigraionId, setSelctedReporMigrationId] = useState("");

  return (
    <div className="min-h-screen w-full">
      <ModalComp
        width={400}
        open={openReportMigrationModal}
        onClose={() => {
          setReportMigrationModal(false);
        }}
      >
        <div className="p-4 rounded-xl bg-white h-[200px]  overflow-y-auto">
          <p className="mb-2 text-sm font-medium">Report ID</p>
          <Input
            type="number"
            value={reportMigartionReportId}
            onChange={(e) => {
              setReportMigationReportId(Number(e.target.value));
            }}
          />
          <p className="mb-2 text-sm font-medium">Validation title</p>
          <Input
            value={reportMigartionTitle}
            onChange={(e) => {
              setReportMigartionTitle(e.target.value);
            }}
          />

          <p className="mb-2 text-sm font-medium">User name</p>
          <Input
            value={reportMigartionMigrateTo}
            onChange={(e) => {
              setReportMigartionMigrateTo(e.target.value);
            }}
          />

          <button
            onClick={() => {
              setReportMigrationLoading(true);
              axiosInstance
                .post("/accounts/migrate_report/", {
                  report_id: reportMigartionReportId,
                  validation_title: reportMigartionTitle,
                  tranfer_to: reportMigartionMigrateTo,
                })
                .then((res) => {
                  setReportMigrationLoading(false);
                  if (res.data.status == "success") {
                    toast.success(res.data.message);
                    handleSave(
                      "report_id",
                      reportMigartionReportId,
                      selectedReporMigraionId
                    );
                  } else {
                    toast.info(res.data.message);
                  }
                })
                .catch((err) => {
                  setReportMigrationLoading(false);
                  console.log(err?.response?.data?.message);
                });
            }}
            disabled={reportMigrationLoading}
            className="bg-black disabled:cursor-not-allowed px-2 py-1 disabled:bg-gray-100"
          >
            Migrate
          </button>
        </div>
      </ModalComp>
      <div className="p-6 w-full">
        <div className="flex justify-between mb-4">
          <div className="text-2xl font-semibold">Company Requests</div>
        </div>
        {
          // isLoading ? (
          //   <div className="w-full h-full flex justify-center items-center">
          //     <Spinner />
          //   </div>
          // ) :
          <div className="w-full overflow-x-auto">
            <div>
              {
                <div>
                  {/* <pre>{JSON.stringify(orgs, {}, 4)}</pre> */}

                  <div className="p-3 mt-2 bg-slate-300 w-full rounded-lg border-0">
                    <Input
                      value={searchOrg}
                      onChange={(e) => {
                        setSeachOrg(e.target.value);
                      }}
                      type="text"
                      placeholder="Search Orgs"
                    />
                    <div className="overflow-y-auto h-36 mt-2 bg-slate-100 p-1 rounded-lg w-full">
                      <table className="w-full ">
                        <thead className="border-b text-left">
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Select
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Org Logo
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Name
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Label
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Account Type
                          </th>
                        </thead>
                        <tbody className="divide-y">
                          {orgLoadings ? (
                            <div className="flex justify-center flex-col items-center">
                              <p>Loading...</p>
                            </div>
                          ) : (
                            getOrgSearchData(
                              searchOrg,
                              orgs?.organisations || []
                            ).map((org) => {
                              return (
                                <tr
                                  className="cursor-pointer"
                                  onClick={() => {
                                    let org_lists =
                                      Object.keys(selectedOrgList);
                                    let old_org_list = JSON.parse(
                                      JSON.stringify(selectedOrgList)
                                    );
                                    if (
                                      org_lists.includes(String(org.org_id))
                                    ) {
                                      delete old_org_list[org.org_id];
                                    } else {
                                      old_org_list[org.org_id] = org;
                                    }
                                    setSelectedOrgList(old_org_list);
                                  }}
                                  key={org.org_id}
                                >
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    <input
                                      type="checkbox"
                                      readOnly
                                      checked={Object.keys(
                                        selectedOrgList
                                      ).includes(String(org?.org_id))}
                                    />
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {
                                      <div
                                        style={{
                                          backgroundImage: `url('${getOrgImage(
                                            org?.org_img,
                                            org?.name
                                          )}')`,
                                        }}
                                        className="h-8 w-8 bg-center bg-no-repeat bg-contain"
                                      />
                                    }
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {org?.name}
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {org?.label}
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {org?.account_type?.name}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="p-3 mt-2 bg-slate-300 w-full rounded-lg border-0">
                    <Input
                      value={userSearch}
                      onChange={(e) => {
                        setUserSearch(e.target.value);
                      }}
                      type="text"
                      placeholder="Search User"
                    />
                    <div className="overflow-y-auto h-36 mt-2 bg-slate-100 p-1 rounded-lg w-full">
                      <table className="w-full ">
                        <thead className="border-b text-left">
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Select
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            UserName
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            First Name
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Last Name
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Org Name
                          </th>
                          <th className="px-2 py-1 text-gray-700 text-sm ">
                            Org Type
                          </th>
                        </thead>
                        <tbody className="divide-y">
                          {isUserListLoading ? (
                            <div className="flex justify-center flex-col items-center">
                              <p>Loading...</p>
                            </div>
                          ) : (
                            userList?.users?.map((user) => {
                              return (
                                <tr
                                  className="cursor-pointer"
                                  onClick={() => {
                                    let user_lists =
                                      Object.keys(selectedUserList);
                                    let old_user_list = JSON.parse(
                                      JSON.stringify(selectedUserList)
                                    );
                                    if (
                                      user_lists.includes(String(user.user_id))
                                    ) {
                                      delete old_user_list[user.user_id];
                                    } else {
                                      old_user_list[user.user_id] = user;
                                    }
                                    setSelectedUserList(old_user_list);
                                  }}
                                  key={user.user_id}
                                >
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    <input
                                      type="checkbox"
                                      readOnly
                                      checked={Object.keys(
                                        selectedUserList
                                      ).includes(String(user?.user_id))}
                                    />
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {user.username}
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {user.first_name}
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {user.last_name}
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {org_id_to_details_mappnig[user.org_id]
                                      ?.name || "not known"}
                                  </td>
                                  <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                                    {org_id_to_details_mappnig[user.org_id]
                                      ?.account_type?.name || "not known"}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-2 flex justify-between items-center">
                      <div className="flex space-x-1 items-center">
                        {userList.current_page > 1 && (
                          <button
                            disabled={isUserListLoading}
                            onClick={() => {
                              setUserPaginatedPageNumber((prev) => prev - 1);
                            }}
                            className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Back
                          </button>
                        )}

                        <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                          <input
                            value={
                              isUserListLoading
                                ? "loading..."
                                : userPaginatedIntermidatePageNumber
                            }
                            type="number"
                            className="disabled:cursor-not-allowed mx-2 w-10"
                            disabled={isUserListLoading}
                            onChange={(e) => {
                              setUserPaginatedIntermidatePageNumber(
                                Number(e.target.value)
                              );
                            }}
                          />
                          <button
                            onClick={() => {
                              if (
                                userPaginatedIntermidatePageNumber > 0 &&
                                userPaginatedIntermidatePageNumber <=
                                  userList.num_pages
                              ) {
                                setUserPaginatedPageNumber(
                                  userPaginatedIntermidatePageNumber
                                );
                              } else {
                                alert("Please enter correct number range");
                              }
                            }}
                            className="bg-black px-1 py-1 text-xs rounded text-white"
                          >
                            Apply
                          </button>
                        </div>
                        {userList.current_page < userList.num_pages && (
                          <button
                            disabled={isUserListLoading}
                            onClick={() => {
                              setUserPaginatedPageNumber((prev) => prev + 1);
                            }}
                            className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Next
                          </button>
                        )}
                      </div>
                      <div className="flex space-x-1">
                        <div>Total Pages: {userList.num_pages}</div>

                        <div>Total Users: {userList.total_users}</div>
                      </div>
                    </div>
                  </div>

                  <p className="font-medium text-sm mt-4">Selected Users : </p>
                  <div className="p-2 mt-2  bg-slate-300 w-full rounded-lg border-0">
                    <div className="h-9 overflow-y-auto bg-white flex flex-wrap space-x-2">
                      {Object.keys(selectedUserList).map((user) => (
                        <div
                          onClick={() => {
                            let userSelected_obj = JSON.parse(
                              JSON.stringify(selectedUserList)
                            );

                            delete userSelected_obj[Number(user)];
                            setSelectedUserList(userSelected_obj);
                          }}
                          className="px-2 py-1 rounded bg-slate-100"
                          key={user}
                        >
                          {selectedUserList[user]?.username} X
                        </div>
                      ))}
                    </div>
                  </div>

                  <p className="font-medium text-sm mt-4">Selected Orgs : </p>
                  <div className="p-2 mt-2  bg-slate-300 w-full rounded-lg border-0">
                    <div className="h-9 overflow-y-auto bg-white flex flex-wrap space-x-2">
                      {Object.keys(selectedOrgList).map((org) => (
                        <div
                          onClick={() => {
                            let userSelected_obj = JSON.parse(
                              JSON.stringify(selectedOrgList)
                            );

                            delete userSelected_obj[Number(org)];
                            setSelectedOrgList(userSelected_obj);
                          }}
                          className="px-2 py-1 rounded bg-slate-100"
                          key={org}
                        >
                          {selectedOrgList[org]?.name} X
                        </div>
                      ))}
                    </div>
                  </div>

                  <p className="font-medium text-sm mt-4">
                    Select from Date :{" "}
                  </p>
                  <Input
                    className="w-full"
                    value={filterTimeRange.startDate}
                    type={"date"}
                    onChange={(e) => {
                      setFilterTimeRange((prev) => ({
                        ...prev,
                        startDate: e.target.value,
                      }));
                    }}
                    placeholder="Enter the email"
                  />
                  <p className="font-medium text-sm mt-4">Select to Date : </p>
                  <Input
                    className="w-full"
                    value={filterTimeRange.endDate}
                    type={"date"}
                    onChange={(e) => {
                      setFilterTimeRange((prev) => ({
                        ...prev,
                        endDate: e.target.value,
                      }));
                    }}
                    placeholder="Enter the email"
                  />

                  <div className="mt-2 mb-4 flex justify-end w-full">
                    <button
                      onClick={() => {
                        setcompanyRequestPaginatedPageNumber(1);
                        setApiRetrigger((prev) => prev + 1);
                      }}
                      disabled={isLoading}
                      className="disabled:cursor-not-allowed text-white bg-black px-2 py-1 rounded-lg"
                    >
                      Apply filter
                    </button>
                  </div>
                  <div className="p-3 mt-2 bg-slate-300 w-full rounded-lg border-0">
                    <div className="w-full flex justify-between items-center">
                      <h2 className="font-medium">Data:</h2>
                      <button
                        onClick={() => handleXLSXDownload()}
                        disabled={IsXLSXDownloading}
                        className="bg-black cursor-pointer disabled:cursor-not-allowed px-1 py-1 text-xs rounded text-white"
                      >
                        {!IsXLSXDownloading ? "Export" : "Loading..."}
                      </button>
                    </div>
                    <div className="overflow-x-auto w-full  overflow-y-auto h-[600px] mt-4 border rounded-lg">
                      {isLoading ? (
                        <p>Loading....</p>
                      ) : (
                        <table className="w-full">
                          <thead className="border-b text-left">
                            {/* <th className="px-2 py-1 text-gray-700 text-sm">
             <input type='checkbox' checked={
                selectedIds.length == data.length
             }
             onClick={()=>{
                if(isOnceClicked){
                    let all_ids = []
                    data.forEach(elem=>{
                        all_ids.push(elem.id)
                    })
                    setSelectedIds(all_ids)
                }
                else{
                    setSelectedIds([])
                }
                etIsOnceClicked(prev=>!prev)                    
             }}
             />Selected</th> */}
                            <th className="px-2 py-1 text-gray-700 text-sm ">
                              Company Name
                            </th>
                            <th className="px-2 py-1 text-gray-700 text-sm ">
                              Company Hq
                            </th>
                            <th className="px-2 py-1 text-gray-700 text-sm ">
                              Company Website
                            </th>
                            <th className="px-2 py-1 text-gray-700 text-sm ">
                              Requested By
                            </th>
                            <th className="px-2 py-1 text-gray-700 text-sm ">
                              Org Name
                            </th>
                            <th className="px-2 py-1 text-gray-700 text-sm ">
                              Date & Time of Request
                            </th>
                            <th className="px-2 py-1 w-[250px] text-gray-700 text-sm ">
                              Owned By
                            </th>
                            <th className="px-2 py-1 w-[250px] text-gray-700 text-sm  flex">
                              Request Status
                            </th>

                            <th className="px-2 py-1 w-[250px] text-gray-700 text-sm ">
                              Migrate to User
                            </th>
                            <th className="px-2 py-1 w-[250px] text-gray-700 text-sm ">
                              Status/Comments
                            </th>
                            <th className="px-2 py-1 w-[250px] text-gray-700 text-sm ">
                              Share Email With Client
                            </th>
                            <th className="px-2 py-1 w-[250px] text-gray-700 text-sm ">
                              Action
                            </th>
                          </thead>
                          <tbody className="divide-y">
                            {data
                              .sort((a, b) => {
                                let date1 = new Date(a.dt_createdOn);
                                let date2 = new Date(b.dt_createdOn);
                                return date2 - date1;
                              })
                              .map((s, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="bg-white odd:bg-gray-50"
                                  >
                                    {/* <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                <input type="checkbox" checked={selectedIds.includes(s?.id)} onClick={()=>{setSelectedIds(prev=>prev.includes(s.id)?prev.filter(id=>id!=s.id):[...prev,s?.id])}}/>  
              </td> */}
                                    <td
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          s?.company_name
                                        );
                                        toast.info(
                                          `${s?.company_name} has been copied to clipboard`
                                        );
                                      }}
                                      className="whitespace-nowrap cursor-pointer px-2 py-2 font-medium text-gray-900"
                                    >
                                      {s?.company_name}
                                    </td>
                                    <td
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          s?.company_hq
                                        );
                                        toast.info(
                                          `${s?.company_hq} has been copied to clipboard`
                                        );
                                      }}
                                      className="whitespace-nowrap cursor-pointer px-2 py-2 font-medium text-gray-900"
                                    >
                                      {s?.company_hq}
                                    </td>
                                    <td
                                      title={s?.company_website}
                                      className="whitespace-nowrap px-2 py-2 cursor-pointer font-medium text-gray-900"
                                    >
                                      <p
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            s?.company_website
                                          );
                                          toast.info(
                                            `${s?.company_website} has been copied to clipboard`
                                          );
                                        }}
                                      >
                                        {s?.company_website.length <= 10
                                          ? s?.company_website
                                          : s?.company_website.slice(0, 10) +
                                            "..."}
                                      </p>
                                      <div
                                        onClick={() => {
                                          window.open(
                                            s?.company_website,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        <OpenInNew className="cursor-pointer" />
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                      {s?.requested_by__username}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                      {s?.requested_by__org_name}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                                      {formateDate(s?.dt_createdOn)}
                                    </td>
                                    <td
                                      className={`whitespace-nowrap px-2 py-2 font-medium text-gray-900`}
                                    >
                                      {s?.meta?.owned_by || "-"}
                                      <Select
                                        isLoading={userListForSeletionLoading}
                                        className="w-full"
                                        options={userListForSeletion.map(
                                          (elem) => ({
                                            label: elem.username,
                                            value: elem.username,
                                          })
                                        )}
                                        onChange={(e) => {
                                          setData((prev) => {
                                            let get_latest_data = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            let element_to_update =
                                              get_latest_data.find(
                                                (it) => it.id == s.id
                                              );
                                            if (element_to_update["meta"]) {
                                              element_to_update["meta"][
                                                "owned_by"
                                              ] = e.value;
                                            } else {
                                              element_to_update["meta"] = {
                                                owned_by: e.value,
                                              };
                                            }

                                            let data_to_return = [];
                                            get_latest_data.forEach(
                                              (element) => {
                                                if (element.id == s.id) {
                                                  data_to_return.push(
                                                    element_to_update
                                                  );
                                                } else {
                                                  data_to_return.push(element);
                                                }
                                              }
                                            );
                                            return data_to_return;
                                          });

                                          handleSave("owned_by", e.value, s.id);
                                        }}
                                      />
                                    </td>
                                    <td
                                      className={`whitespace-nowrap px-2 py-2 font-medium text-gray-900`}
                                    >
                                      {s?.meta?.request_status || "-"}
                                      <Select
                                        className="w-full"
                                        options={[
                                          {
                                            label: "Yet to be picked",
                                            value: "Yet to be picked",
                                          },
                                          {
                                            label: "In Progress",
                                            value: "In Progress",
                                          },
                                          {
                                            label: "In Review",
                                            value: "In Review",
                                          },
                                          {
                                            label: "Shared with CS",
                                            value: "Shared with CS",
                                          },
                                          {
                                            label: "Blocked",
                                            value: "Blocked",
                                          },
                                        ]}
                                        onChange={(e) => {
                                          setData((prev) => {
                                            let get_latest_data = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            let element_to_update =
                                              get_latest_data.find(
                                                (it) => it.id == s.id
                                              );
                                            if (element_to_update["meta"]) {
                                              element_to_update["meta"][
                                                "request_status"
                                              ] = e.value;
                                            } else {
                                              element_to_update["meta"] = {
                                                request_status: e.value,
                                              };
                                            }

                                            let data_to_return = [];
                                            get_latest_data.forEach(
                                              (element) => {
                                                if (element.id == s.id) {
                                                  data_to_return.push(
                                                    element_to_update
                                                  );
                                                } else {
                                                  data_to_return.push(element);
                                                }
                                              }
                                            );
                                            return data_to_return;
                                          });

                                          handleSave(
                                            "request_status",
                                            e.value,
                                            s.id
                                          );
                                        }}
                                      />
                                    </td>
                                    <td
                                      className={`whitespace-nowrap w-[250px] flex px-2 py-2 font-medium  text-gray-900`}
                                    >
                                      <Input
                                        className="w-full"
                                        value={s?.meta?.migrate_to_user}
                                        onChange={(e) => {
                                          setData((prev) => {
                                            let get_latest_data = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            let element_to_update =
                                              get_latest_data.find(
                                                (it) => it.id == s.id
                                              );
                                            if (element_to_update["meta"]) {
                                              element_to_update["meta"][
                                                "migrate_to_user"
                                              ] = e.target.value;
                                            } else {
                                              element_to_update["meta"] = {
                                                migrate_to_user: e.value,
                                              };
                                            }

                                            let data_to_return = [];
                                            get_latest_data.forEach(
                                              (element) => {
                                                if (element.id == s.id) {
                                                  data_to_return.push(
                                                    element_to_update
                                                  );
                                                } else {
                                                  data_to_return.push(element);
                                                }
                                              }
                                            );
                                            return data_to_return;
                                          });
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key == "Enter") {
                                            handleSave(
                                              "migrate_to_user",
                                              s?.meta?.migrate_to_user,
                                              s.id
                                            );
                                          }
                                        }}
                                      />
                                    </td>

                                    <td className="whitespace-nowrap w-[250px] px-2 py-2 font-medium text-gray-900">
                                      <Input
                                        className="w-full"
                                        value={s?.meta?.comment}
                                        onChange={(e) => {
                                          setData((prev) => {
                                            let get_latest_data = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            let element_to_update =
                                              get_latest_data.find(
                                                (it) => it.id == s.id
                                              );
                                            if (element_to_update["meta"]) {
                                              element_to_update["meta"][
                                                "comment"
                                              ] = e.target.value;
                                            } else {
                                              element_to_update["meta"] = {
                                                comment: e.value,
                                              };
                                            }

                                            let data_to_return = [];
                                            get_latest_data.forEach(
                                              (element) => {
                                                if (element.id == s.id) {
                                                  data_to_return.push(
                                                    element_to_update
                                                  );
                                                } else {
                                                  data_to_return.push(element);
                                                }
                                              }
                                            );
                                            return data_to_return;
                                          });
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key == "Enter") {
                                            handleSave(
                                              "comment",
                                              s?.meta?.comment,
                                              s.id
                                            );
                                          }
                                        }}
                                      />
                                    </td>
                                    <td className="whitespace-nowrap px-2 w-[250px] py-2 font-medium text-gray-900">
                                      {s?.meta?.share_with_the_client || "-"}
                                      <Select
                                        className="w-full"
                                        options={[
                                          {
                                            label: "Yes",
                                            value: "Yes",
                                          },
                                          {
                                            label: "No",
                                            value: "No",
                                          },
                                        ]}
                                        onChange={(e) => {
                                          setData((prev) => {
                                            let get_latest_data = JSON.parse(
                                              JSON.stringify(prev)
                                            );
                                            let element_to_update =
                                              get_latest_data.find(
                                                (it) => it.id == s.id
                                              );
                                            if (element_to_update["meta"]) {
                                              element_to_update["meta"][
                                                "share_with_the_client"
                                              ] = e.value;
                                            } else {
                                              element_to_update["meta"] = {
                                                share_with_the_client: e.value,
                                              };
                                            }

                                            let data_to_return = [];
                                            get_latest_data.forEach(
                                              (element) => {
                                                if (element.id == s.id) {
                                                  data_to_return.push(
                                                    element_to_update
                                                  );
                                                } else {
                                                  data_to_return.push(element);
                                                }
                                              }
                                            );
                                            return data_to_return;
                                          });
                                          handleSave(
                                            "share_with_the_client",
                                            e.value,
                                            s.id
                                          );
                                        }}
                                      />
                                    </td>
                                    <td className="whitespace-nowrap px-2 w-[250px] py-2 font-medium text-gray-900">
                                      <button
                                        onClick={() => {
                                          setReportMigrationModal(true);
                                          setSelctedReporMigrationId(s["id"]);
                                        }}
                                        className="bg-black px-2 py-1 text-white rounded-lg"
                                      >
                                        Migrate Report
                                      </button>
                                      <button
                                        onClick={() => {
                                          let permalink = prompt(
                                            "Enter Permalink for migration ?"
                                          );
                                          if (permalink) {
                                            handleSave(
                                              "permalink",
                                              permalink,
                                              s.id
                                            );
                                            handleMigrations(permalink);
                                          }
                                        }}
                                        className="ml-2 bg-black px-2 py-1 text-white rounded-lg"
                                      >
                                        Migrate Company
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div className="mt-2 flex justify-between items-center">
                      <div className="flex space-x-1 items-center">
                        {CompanyRequestsResponse.current_page > 1 && (
                          <button
                            disabled={isLoading}
                            onClick={() => {
                              setcompanyRequestPaginatedPageNumber(
                                (prev) => prev - 1
                              );
                            }}
                            className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Back
                          </button>
                        )}

                        <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                          <input
                            value={
                              isLoading
                                ? "loading..."
                                : companyRequestPaginatedIntermidatePageNumber
                            }
                            type="number"
                            className="disabled:cursor-not-allowed mx-2 w-10"
                            disabled={isLoading}
                            onChange={(e) => {
                              setcompanyRequestPaginatedIntermidatePageNumber(
                                Number(e.target.value)
                              );
                            }}
                          />
                          <button
                            onClick={() => {
                              if (
                                companyRequestPaginatedIntermidatePageNumber >
                                  0 &&
                                companyRequestPaginatedIntermidatePageNumber <=
                                  CompanyRequestsResponse.num_pages
                              ) {
                                setcompanyRequestPaginatedPageNumber(
                                  companyRequestPaginatedIntermidatePageNumber
                                );
                              } else {
                                alert("Please enter correct number range");
                              }
                            }}
                            className="bg-black px-1 py-1 text-xs rounded text-white"
                          >
                            Apply
                          </button>
                        </div>
                        {CompanyRequestsResponse.current_page <
                          CompanyRequestsResponse.num_pages && (
                          <button
                            disabled={isLoading}
                            onClick={() => {
                              setcompanyRequestPaginatedPageNumber(
                                (prev) => prev + 1
                              );
                            }}
                            className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                          >
                            Next
                          </button>
                        )}
                      </div>
                      <div className="flex space-x-1">
                        <div>
                          Total Pages: {CompanyRequestsResponse.num_pages}
                        </div>

                        <div>
                          Total Company Requests:{" "}
                          {CompanyRequestsResponse.total_company_requests}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default CompanyRequests;
